import React, { useEffect } from "react";
import AuthHeader from "../../layout/AuthHeader";
import BottomNav from "../../layout/BottomNav";
import productImage from "../../assets/images/LuckydrawItem1.jpg";
import { useNavigate } from "react-router-dom";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";

export default function PrizeWinners() {
  const [changeLanguage, setChangeLanguage] = React.useState(
    localStorage.getItem("currentLanguage") || "en"
  );
  const changeLanguageData = (languageType) => {
    setChangeLanguage(languageType);
  };

  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);
  const winnerlist = useSelector(
    (state) => state?.others?.winnerList?.data?.data
  );

  React.useEffect(() => {
    localStorage.setItem("currentLanguage", changeLanguage);
  }, [changeLanguage]);

  useEffect(() => {
    callApi("others/winnerList").executeDispatch();
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <AuthHeader title="Prize Winners" backkey={true} />

      <div
        id="body"
        className="auth bottombar cursor-default"
        style={{ overflow: "hidden auto", marginTop: "10px" }}
      >
        <div className="container p-lr-16">
          {/* June 2024 Winners */}
          {/* <section className="mb-4">
            <h2 className="fs-16 font-bold mb-2">June 2024 Winners</h2>
            <p className="fs-12 text-gray-500">
              {getLanguageFile.exchange_ticket_get_free_products}
            </p>
          </section> */}

          {/* Winner List */}
          {winnerlist?.map((item, index) => (
            <div
              key={index}
              className="bg-foundation-grey-light-hover p-4 rounded-lg mb-4"
            >
              <div className="flex items-center mb-4">
                <img
                  src={
                    item?.winner?.profile_image
                      ? item?.winner?.profile_image
                      : "https://via.placeholder.com/40"
                  }
                  alt="Winner"
                  className="w-[40px] h-[40px] rounded-full mr-2"
                />
                <div className="flex flex-col">
                  <h3 className="fs-14 font-medium">{item?.name}</h3>
                  <p className="fs-12 text-gray-500">
                    {item?.winner?.township}
                  </p>
                </div>
                <p className="fs-12 text-gray-500 ml-auto">
                  {item?.created_at}
                </p>
              </div>
              <div className="flex items-center">
                <img
                  src={
                    item?.product?.feature_image
                      ? item?.product?.feature_image
                      : productImage
                  }
                  alt="Product"
                  className="w-1/3 rounded-md"
                />
                <div className="ml-4">
                  <h3 className="fs-14 font-bold">{item?.product?.name}</h3>
                  <p className="fs-11">{item?.product?.category}</p>
                  {/* <p className="fs-11">{getLanguageFile.original_price}</p> */}
                  <p className="fs-11 font-bold">
                    MMK{item?.product?.actual_price}
                  </p>
                  <p className="fs-11">
                    Batch {item?.batch}
                  </p>
                  <p className="fs-11 text-gray-500">
                    {getLanguageFile.open_at} {item?.opened_at }
                  </p>
                </div>
              </div>
              <div className="text-right mt-4">
                <button onClick={()=> navigate("/prize-winner-detail", { state: { data: item?.id } })} className="text-blue-500 fs-12 font-semibold">
                  {getLanguageFile.see_detail}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <BottomNav getLanguageFile={getLanguageFile} />
    </>
  );
}
