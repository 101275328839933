import React, { useState } from "react";
import { InformationCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../components/loading/LoadingComponent";
import { BeatLoader, ClipLoader } from "react-spinners";

const ConfirmReferralGiftModal = ({ show, name, onClose, onConfirm }) => {
  const navigate = useNavigate();

  if (!show) return null;
  const changeLanguage = window.localStorage.currentLanguage
    ? window.localStorage.currentLanguage
    : "en";

  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white w-[300px] p-6 rounded-lg relative">
        <button onClick={onClose} className="absolute top-2 right-2">
          <XMarkIcon className="w-5 h-5 text-gray-500" />
        </button>
        <div className="flex justify-center mb-4">
          <InformationCircleIcon className="w-10 h-10 text-green-500" />
        </div>
        <h2 className="fs-14 font-bold mb-4 text-center cursor-default">
          {getLanguageFile.using_your_referral_point_claim} {name} {getLanguageFile.using_your_referral_point_request}
        </h2>

        <div className="flex justify-between space-x-2 fs-14">
          <button
            onClick={onClose}
            className="bg-white border-[1px] border-foundation-grey-normal-hover text-500 font-semibold rounded-full px-4 py-2 flex-1"
          >
            {getLanguageFile.cancel}
          </button>
          <LoadingComponent
            loadingGroup={"claimReferralGift"}
            loadingDesign={
              <button
                onClick={() => {
                  onConfirm();
                }}
                className="bg-yellow-400 text-500 font-semibold rounded-full px-4 py-2 flex-1"
              >
                <ClipLoader color="#ffffff" size={13} />
              </button>
            }
          >
            <button
              onClick={() => {
                onConfirm();
              }}
              className="bg-yellow-400 text-500 font-semibold rounded-full px-4 py-2 flex-1"
            >
              {getLanguageFile.confirm}
            </button>
          </LoadingComponent>
        </div>
      </div>
    </div>
  );
};

export default ConfirmReferralGiftModal;
