import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../layout/Header";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import Banner1 from "../../assets/images/banner1.png";
import LuckydrawItem1 from "../../assets/images/LuckydrawItem1.jpg";
import {
  ArrowLeftIcon,
  StarIcon,
  PencilSquareIcon,
  PlusIcon,
  MinusIcon,
  HomeIcon,
  ShoppingCartIcon,
  CubeIcon,
  TruckIcon,
  GiftIcon,
} from "@heroicons/react/24/solid";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import BottomNav from "../../layout/BottomNav";
import AuthHeader from "../../layout/AuthHeader";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";
import AlertPopup from "../../components/AlertPopUp/AlertPopUp";
import ReviewModal from "../product/ReviewModal";
import LoadingComponent from "../../components/loading/LoadingComponent";
import { ClipLoader } from "react-spinners";

export default function MyOrdersShippingDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || {};
  const [changeLanguage, setChangeLanguage] = useState(
    window.localStorage.currentLanguage || "en"
  );
  const [alertmessage, setAlertmessage] = useState("");
  const [reviewAlertmessage, setReviewAlertmessage] = useState("");
  const [serverError, setServerError] = useState(null);
  const [reviewModalToggle, setReviewModalToggle] = useState(false);

  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);
  const shippingOrdersDetail = useSelector(
    (state) => state?.history?.shippingOrdersDetail?.data?.data
  );

  useEffect(() => {
    callApi("history/shippingOrdersDetail")
      .withKeyParameter({ id: id })
      .loadingGroup("MyOrdersShippingDetail")
      .executeDispatch();
  }, []);

  function handleReceived() {
    callApi("history/shippingOrderReceive")
      .withBody({ shipping_order_id: id })
      .execute()
      .then((res) => {
        if (res?.status_code == 200) {
          setAlertmessage(res?.message || "Success");
        }
      });
  }

  return (
    <>
      {alertmessage && (
        <AlertPopup
          type={"success"}
          btnLabel={"Ok"}
          text={alertmessage}
          onClick={() => {
            setAlertmessage(null);
            setReviewModalToggle(true);
          }}
        />
      )}
      {reviewAlertmessage && (
        <AlertPopup
          type={"success"}
          btnLabel={"Ok"}
          text={reviewAlertmessage}
          onClick={() => {
            setReviewAlertmessage(null);
          }}
        />
      )}
      {serverError && (
        <AlertPopup
          type={"error"}
          btnLabel={"Ok"}
          text={serverError}
          onClick={() => setServerError(null)}
        />
      )}

      <AuthHeader title={getLanguageFile.my_orders} backkey={true} />

      <div
        id="body"
        className={
          shippingOrdersDetail?.status == "received"
            ? "auth"
            : "auth bottombutton"
        }
        style={{ overflow: "hidden auto", marginTop: "10px" }}
      >
        <LoadingComponent
          loadingGroup={"MyOrdersShippingDetail"}
          loadingDesign={
            <div className="flex justify-center content-center mt-5 w-100">
              <ClipLoader color="#fed801" size={20} />
            </div>
          }
        >
          <section className="m-b-16 p-lr-16 container">
            <div className="flex m-b-24">
              <div className="flex justify-between items-center border-[1px] border-foundation-grey-light-hover bg-white m-l-4 rounded-full font-semibold text-500">
                <button
                  onClick={() => navigate("/my-orders-to-ship")}
                  className="p-all-8 font-medium text-500 fs-12"
                >
                  {getLanguageFile.to_ship}
                </button>
              </div>

              <div
                className={`flex items-center justify-between bg-white text-500 font-semibold rounded-full m-l-4 border-[1px] ${
                  shippingOrdersDetail?.status == "received"
                    ? `border-foundation-grey-light-hover`
                    : `border-foundation-grey-normal-hover`
                }`}
              >
                <button
                  onClick={() => navigate("/my-orders-shipping")}
                  className="p-all-8 font-medium text-500 fs-12"
                >
                  {getLanguageFile.shipping}
                </button>
              </div>

              <div
                className={`flex items-center justify-between bg-white text-500 font-semibold rounded-full m-l-4 border-[1px] ${
                  shippingOrdersDetail?.status == "received"
                    ? `border-foundation-grey-normal-hover`
                    : `border-foundation-grey-light-hover`
                }`}
              >
                <button
                  onClick={() => navigate("/my-orders-received")}
                  className="p-all-8 font-medium text-500 fs-12"
                >
                  {getLanguageFile.received}
                </button>
              </div>
            </div>
          </section>

          <section className="m-b-16 p-lr-16 container">
            <div className="flex flex-col">
              <span className="flex items-center mb-1 leading-normal">
                {/* <span className="font-bold fs-11 me-1">MMK</span> */}
                <span className="font-extrabold leading-[16px] fs-16">
                  Shipping ID: {shippingOrdersDetail?.shipping_order_id}
                </span>
              </span>

              {shippingOrdersDetail?.status && (
                <div className="mb-1 text-ellipsis whitespace-nowrap overflow-hidden fs-11">
                  Status:{" "}
                  {shippingOrdersDetail?.status == "approved" ? (
                    <span
                      style={{ color: "green" }}
                      className="mb-1 text-ellipsis whitespace-nowrap overflow-hidden fs-11"
                    >
                      {getLanguageFile.on_the_way}
                    </span>
                  ) : shippingOrdersDetail?.status == "pending" ? (
                    <span
                      style={{ color: "orange" }}
                      className="mb-1 text-ellipsis whitespace-nowrap overflow-hidden fs-11"
                    >
                     {getLanguageFile.processing}
                    </span>
                  ) : shippingOrdersDetail?.status == "received" ? (
                    <span
                      style={{ color: "DarkCyan" }}
                      className="mb-1 text-ellipsis whitespace-nowrap overflow-hidden fs-11"
                    >
                      {getLanguageFile.received}
                    </span>
                  ) : (
                    shippingOrdersDetail?.status
                  )}
                </div>
              )}

              {shippingOrdersDetail?.shipping_order_items && (
                <div className="mb-1 text-ellipsis whitespace-nowrap overflow-hidden fs-11">
                  {getLanguageFile.items_uppercase}: {shippingOrdersDetail?.shipping_order_items?.length}
                </div>
              )}

              <div className="text-ellipsis whitespace-nowrap overflow-hidden fs-11">
                {getLanguageFile.total_price}: {shippingOrdersDetail?.shipping_price}
              </div>

              <div className="text-ellipsis whitespace-nowrap overflow-hidden fs-11">
                Payment: {shippingOrdersDetail?.payment}
              </div>

              <div className="text-ellipsis whitespace-nowrap overflow-hidden fs-11">
              {getLanguageFile.order_date}: {shippingOrdersDetail?.order_date}
              </div>
            </div>
          </section>

          <section className="m-b-16 p-lr-16 container">
            <div className="flex flex-col justify-center">
              <div className="flex justify-center items-center space-x-4 w-full">
                {/* Step 1 */}
                <div className="flex flex-col items-center">
                  <div className="flex justify-center items-center bg-gold-500 mb-1 rounded-full w-10 h-10">
                    <CubeIcon className="w-6 h-6 text-500" />
                    {/* Adjust the size and color as needed */}
                  </div>
                  <span className="font-bold text-500 fs-12">To Ship</span>
                </div>

                {/* Progress Line 1 */}
                <div className="bg-gold-500 mb-[15px] w-16 h-1"></div>

                {/* Step 2 */}
                <div className="flex flex-col items-center">
                  <div className="flex justify-center items-center bg-gold-500 mb-1 rounded-full w-10 h-10">
                    <TruckIcon className="w-6 h-6 text-500" />
                  </div>
                  <span className="font-bold text-500 fs-12">Shipping</span>
                </div>

                {/* Progress Line 2 */}
                <div
                  className={`w-16 h-1 ${
                    shippingOrdersDetail?.status == "received"
                      ? `bg-gold-500`
                      : `bg-gray-300`
                  }  mb-[15px]`}
                ></div>

                {/* Step 3 */}
                <div className="flex flex-col items-center">
                  <div
                    className={`w-10 h-10 flex items-center justify-center rounded-full ${
                      shippingOrdersDetail?.status == "received"
                        ? `bg-gold-500`
                        : `bg-gray-300`
                    } mb-1`}
                  >
                    <PencilSquareIcon className="w-6 h-6 text-500" />
                  </div>
                  <span className="font-bold text-500 fs-12">Received</span>
                </div>
              </div>
            </div>
          </section>

          <section className="m-b-16 p-lr-16 container">
            <div className="flex flex-col">
              {shippingOrdersDetail?.shipping_order_items?.map((item, key) => (
                <div key={key}>
                  <div className="relative flex">
                    <div href="product-detail" className="relative w-5/12">
                      <img
                        src={
                          item?.product?.feature_image
                            ? item?.product?.feature_image
                            : LuckydrawItem1
                        }
                        alt="Slide 1"
                        className=""
                      />

                      {/* <div className="top-2 left-1 absolute flex justify-between items-center bg-red-500 m-l-4 p-lr-8 rounded-full font-semibold text-white">
                      <span className="font-medium text-white fs-8">
                        60% off
                      </span>
                    </div> */}
                    </div>

                    <div className="flex-grow p-lr-8 p-tb-16 w-7/12">
                      <div className="text-ellipsis whitespace-nowrap overflow-hidden fs-11">
                        {item?.product?.name}
                      </div>
                      <div className="text-ellipsis whitespace-nowrap overflow-hidden fs-11">
                        {item?.product?.category}
                      </div>
                      <span className="flex items-center leading-normal">
                        <span className="font-bold fs-11 me-1">MMK</span>
                        <span className="font-extrabold leading-[16px] fs-16">
                          {item?.product?.actual_price}
                        </span>
                      </span>

                      {item?.type && (
                        <div className="mb-1 text-ellipsis whitespace-nowrap overflow-hidden fs-11">
                          Type: {item?.type}
                        </div>
                      )}

                      {item?.status && (
                        <div className="mb-1 text-ellipsis whitespace-nowrap overflow-hidden fs-11">
                          Status:{" "}
                          {item?.status == "approved" ? (
                            <span
                              style={{ color: "green" }}
                              className="mb-1 text-ellipsis whitespace-nowrap overflow-hidden fs-11"
                            >
                              {getLanguageFile.on_the_way}
                            </span>
                          ) : item?.status == "pending" ? (
                            <span
                              style={{ color: "orange" }}
                              className="mb-1 text-ellipsis whitespace-nowrap overflow-hidden fs-11"
                            >
                              {getLanguageFile.processing}
                            </span>
                          ) : item?.status == "received" ? (
                            <span
                              style={{ color: "DarkCyan" }}
                              className="mb-1 text-ellipsis whitespace-nowrap overflow-hidden fs-11"
                            >
                             {getLanguageFile.received}
                            </span>
                          ) : (
                            item?.status
                          )}
                        </div>
                      )}

                      <div className="text-ellipsis whitespace-nowrap overflow-hidden fs-11">
                        {getLanguageFile.weight}: {item?.product?.kg}
                      </div>
                      {/* <div className="m-b-16">
                      <span className="text-300 leading-normal fs-11 me-1">
                        {getLanguageFile.shipping}
                      </span>

                      <span className="text-red-500 leading-[16px] fs-11">
                        MMK 5,000
                      </span>
                    </div> */}

                      {/* <div className="flex m-b-8">
                      <div className="flex justify-between items-center border-[2px] border-foundation-grey-light-hover bg-foundation-grey-light-hover m-l-4 p-lr-8 p-tb-4 rounded-full h-[24px] font-semibold text-500">
                        <span className="font-semibold text-500 fs-12">
                          {getLanguageFile.arrive_in} 2 to 3 days
                        </span>
                      </div>
                    </div> */}
                    </div>
                  </div>

                  <div className="border-Light/active mb-4 border-t-2"></div>
                </div>
              ))}
            </div>
          </section>
        </LoadingComponent>
      </div>

      {shippingOrdersDetail?.status != "received" && (
        <div className="bottom-0 bg-white shadow-md-top p-lr-16 w-100 max-w-[393px] sm:max-w-[none]">
          <div className="gap-2 grid grid-cols-1">
            <LoadingComponent
              loadingGroup={"MyOrdersShippingDetail"}
              loadingDesign={
                <button
                  disabled={true}
                  onClick={() => handleReceived()}
                  className="flex justify-center items-center bg-gold-500 disabled:bg-gray-300 disabled:opacity-50 m-tb-16 p-lr-32 p-tb-12 rounded-full w-100 font-semibold text-500 disabled:cursor-not-allowed"
                >
                  {getLanguageFile.received}
                </button>
              }
            >
              <button
                disabled={
                  shippingOrdersDetail?.status == "approved" ? false : true
                }
                onClick={() => handleReceived()}
                className="flex justify-center items-center bg-gold-500 disabled:bg-gray-300 disabled:opacity-50 m-tb-16 p-lr-32 p-tb-12 rounded-full w-100 font-semibold text-500 disabled:cursor-not-allowed"
              >
                {getLanguageFile.received}
              </button>
            </LoadingComponent>
          </div>
        </div>
      )}

      <ReviewModal
        visible={reviewModalToggle}
        onClose={() => setReviewModalToggle(false)}
        setAlertmessage={setReviewAlertmessage}
        setServerError={setServerError}
      />
    </>
  );
}
