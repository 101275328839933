import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../layout/Header";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import Banner1 from "../../assets/images/banner1.png";
import LuckydrawItem1 from "../../assets/images/LuckydrawItem1.jpg";
import {
  ArrowLeftIcon,
  StarIcon,
  PencilSquareIcon,
  PlusIcon,
  MinusIcon,
  HomeIcon,
  ShoppingCartIcon,
  GiftIcon,
} from "@heroicons/react/24/solid";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import BottomNav from "../../layout/BottomNav";
import AuthHeader from "../../layout/AuthHeader";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";
import LoadingComponent from "../../components/loading/LoadingComponent";
import { ClipLoader } from "react-spinners";

export default function MyOrdersShipping() {
  const navigate = useNavigate();
  const [changeLanguage, setChangeLanguage] = useState(
    window.localStorage.currentLanguage || "en"
  );
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);
  const shippingOrders = useSelector(
    (state) => state?.history?.shippingOrders?.data?.data
  );

  useEffect(() => {
    callApi("history/shippingOrders")
      .loadingGroup("MyOrdersShipping")
      .executeDispatch();
  }, []);

  return (
    <>
      <AuthHeader
        title={getLanguageFile.my_orders}
        backkey={true}
        route="/profile"
      />

      <div
        id="body"
        className="auth"
        style={{ overflow: "hidden auto", marginTop: "10px" }}
      >
        <section className="m-b-16 p-lr-16 container">
          <div className="flex m-b-24">
            <div className="flex justify-between items-center border-[1px] border-foundation-grey-light-hover bg-white m-l-4 rounded-full font-semibold text-500">
              <button
                onClick={() => navigate("/my-orders-to-ship")}
                className="p-all-8 font-medium text-500 fs-12"
              >
                {getLanguageFile.to_ship}
              </button>
            </div>

            <div className="flex justify-between items-center border-[1px] border-foundation-grey-normal-hover bg-white m-l-4 rounded-full font-semibold text-500">
              <button
                onClick={() => navigate("/my-orders-shipping")}
                className="p-all-8 font-medium text-500 fs-12"
              >
                {getLanguageFile.shipping}
              </button>
            </div>

            <div className="flex justify-between items-center border-[1px] border-foundation-grey-light-hover bg-white m-l-4 rounded-full font-semibold text-500">
              <button
                onClick={() => navigate("/my-orders-received")}
                className="p-all-8 font-medium text-500 fs-12"
              >
                {getLanguageFile.received}
              </button>
            </div>
          </div>
        </section>

        <LoadingComponent
          loadingGroup={"MyOrdersShipping"}
          loadingDesign={
            <div className="flex justify-center content-center mt-5 w-100">
              <ClipLoader color="#fed801" size={20} />
            </div>
          }
        >
          <section className="m-b-16 p-lr-16 container">
            <div className="flex flex-col">
              {shippingOrders?.map((item, key) => (
                <div key={key}>
                  <div className="relative flex">
                    {/* <div href="product-detail" className="relative w-5/12">
                    <img
                      src={
                        item?.product?.feature_image
                          ? item?.product?.feature_image
                          : LuckydrawItem1
                      }
                      alt="Slide 1"
                      className=""
                    />

                    <div className="top-2 left-1 absolute flex justify-between items-center bg-red-500 m-l-4 p-lr-8 rounded-full font-semibold text-white">
                      <span className="font-medium text-white fs-8">
                        60% off
                      </span>
                    </div>
                  </div> */}

                    <div
                      onClick={() =>
                        navigate("/my-orders-shipping-detail", {
                          state: { id: item?.id },
                        })
                      }
                      className="flex-grow p-lr-8 p-tb-16 w-7/12 cursor-pointer"
                    >
                      <span className="flex items-center mb-1 leading-normal">
                        {/* <span className="font-bold fs-11 me-1">MMK</span> */}
                        <span className="font-extrabold leading-[16px] fs-16">
                          Shipping ID: {item?.shipping_order_id}
                        </span>
                      </span>

                      {item?.status && (
                        <div className="mb-1 text-ellipsis whitespace-nowrap overflow-hidden fs-11">
                          Status:{" "}
                          {item?.status == "approved" ? (
                            <span
                              style={{ color: "green" }}
                              className="mb-1 text-ellipsis whitespace-nowrap overflow-hidden fs-11"
                            >
                              {getLanguageFile.on_the_way}
                            </span>
                          ) : item?.status == "pending" ? (
                            <span
                              style={{ color: "orange" }}
                              className="mb-1 text-ellipsis whitespace-nowrap overflow-hidden fs-11"
                            >
                              {getLanguageFile.processing}
                            </span>
                          ) : item?.status == "received" ? (
                            <span
                              style={{ color: "DarkCyan" }}
                              className="mb-1 text-ellipsis whitespace-nowrap overflow-hidden fs-11"
                            >
                              {getLanguageFile.received}
                            </span>
                          ) : (
                            item?.status
                          )}
                        </div>
                      )}

                      {item?.shipping_order_items && (
                        <div className="mb-1 text-ellipsis whitespace-nowrap overflow-hidden fs-11">
                          {getLanguageFile.items_uppercase}: {item?.shipping_order_items}
                        </div>
                      )}

                      <div className="text-ellipsis whitespace-nowrap overflow-hidden fs-11">
                        {getLanguageFile.total_price}: {item?.shipping_price}
                      </div>

                      {/* <div className="text-ellipsis whitespace-nowrap overflow-hidden fs-11">
                      Payment: {item?.payment}
                    </div> */}

                      <div className="text-ellipsis whitespace-nowrap overflow-hidden fs-11">
                      {getLanguageFile.order_date}: {item?.order_date}
                      </div>

                      {/* <div className="m-b-16">
                      <span className="text-300 leading-normal fs-11 me-1">
                        {getLanguageFile.shipping}
                      </span>

                      <span className="text-red-500 leading-[16px] fs-11">
                        MMK 5,000
                      </span>
                    </div> */}

                      {/* <div className="flex m-b-8">
                      <div className="flex justify-between items-center border-[2px] border-foundation-grey-light-hover bg-foundation-grey-light-hover m-l-4 p-lr-8 p-tb-4 rounded-full h-[24px] font-semibold text-500">
                        <span className="font-semibold text-500 fs-12">
                          {getLanguageFile.arrive_in} 2 to 3 days
                        </span>
                      </div>
                    </div> */}
                    </div>
                  </div>

                  <div className="border-Light/active mb-4 border-t-2"></div>
                </div>
              ))}
            </div>
          </section>
        </LoadingComponent>
      </div>
    </>
  );
}
