import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Header from "../../layout/Header";
import BottomNav from "../../layout/BottomNav";
import { StarIcon } from "@heroicons/react/24/solid";
import productImage1 from "../../assets/images/LuckydrawItem1.jpg";
import { useNavigate } from "react-router-dom";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";
import { dispatchStore } from "../../lib/dispatchStore";
import { tokenProductSlice } from "../../helper/customSlice";
import { ClipLoader } from "react-spinners";

export default function Token() {
  const navigate = useNavigate();
  const observerRef = useRef(null);

  const [changeLanguage, setChangeLanguage] = useState(
    localStorage.getItem("currentLanguage") || "en"
  );
  const [activeTab, setActiveTab] = useState("getToken");
  const AccessToken = useSelector((state) => state.AccessToken);

  const changeLanguageData = (languageType) => {
    setChangeLanguage(languageType);
  };

  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);
  const Listpage = useSelector((state) => state?.tokenProductSlice?.Listpage);
  const tokenProduct = useSelector((state) => state?.tokenProductSlice);
  const tokenProductLoading = useSelector(
    (state) => state.loading["tokenProductLoading"]
  );
  const reachEnd = useSelector((state) => state?.tokenProductSlice?.reachEnd);

  const tokenHistory = useSelector(
    (state) => state?.history?.token?.data?.data
  );
  const profile = useSelector((state) => state?.profile?.profile?.data?.data);

  function handleBuyNow(product) {
    if (AccessToken) {
      navigate("/confirm-order", {
        state: { product: product, qty: 1 },
      });
    } else {
      navigate("/signin");
    }
  }

  const fetchItems = async (page) => {
    if (reachEnd == false && page > 0) {
      callApi("products/token")
        .withKeyParameter({
          page: page,
          perpage: tokenProduct?.per_page ? tokenProduct?.per_page : 20,
        })
        .loadingGroup(
          page == 1 ? "centerLoadTokenProduct" : "tokenProductLoading"
        )
        .execute()
        .then((res) => {
          if (res?.data?.data?.length > 0) {
            // setTotalLength(res?.total_products)
            if (page == 1) {
              dispatchStore(
                tokenProductSlice.actions.setTokenProductListing(res)
              );
            } else {
              dispatchStore(
                tokenProductSlice.actions.updateTokenProductListing(res)
              );
            }
          }
        });
    }
  };

  useEffect(() => {
    console.log(observerRef.current, "observerRef.current");
    const observer = new IntersectionObserver(
      (entries) => {
        console.log(entries[0].isIntersecting, "entries[0].isIntersecting");
        if (entries[0].isIntersecting) {
          dispatchStore(tokenProductSlice.actions.plusListpage());
        }
      },
      { root: document.querySelector("#body"), threshold: 0.01 }
    );
    if (observerRef.current) {
      observer.observe(observerRef.current);
    }
    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    fetchItems(Listpage);
  }, [Listpage]);

  useEffect(() => {
    localStorage.setItem("currentLanguage", changeLanguage);
  }, [changeLanguage]);

  useEffect(() => {
    if (activeTab == "tokenHistory") {
      callApi("history/token").executeDispatch();
    }
  }, [activeTab]);

  const renderContent = () => {
    if (activeTab === "getToken") {
      return (
        <section className="grid grid-cols-2 gap-4">
          {tokenProduct?.list?.map((item, key) => (
            <div key={key} className="bg-white rounded-lg shadow-md p-4">
              <button
                onClick={() => navigate(`/product-detail?id=${item?.slug}`)}
              >
                <img
                  src={
                    item?.feature_image ? item?.feature_image : productImage1
                  }
                  alt="Gift Box"
                  className="w-full rounded-lg mb-4"
                />
                <h3 className="fs-11 font-normal text-400 mb-1 text-start">
                  {item?.name}
                </h3>
                <span className="flex items-center mb-1">
                  <p className="fs-11 font-bold text-500">MMK</p>
                  <p className="fs-16 font-extrabold text-500">
                    {item?.actual_price}
                  </p>
                </span>
                <span className="flex items-center mb-1">
                  <span className="fs-12 font-bold text-400">
                    {getLanguageFile.tokenpage_get}{" "}
                  </span>
                  <span className="w-[22px] h-[22px] flex items-center justify-center p-1 bg-gold-500 rounded-full mx-1">
                    <StarIcon id="StarIcon" className=" text-white" />
                  </span>
                  <span className="fs-14 font-bold text-gold-900">
                    {item?.token} {getLanguageFile.tokens}
                  </span>
                </span>
              </button>

              <button
                onClick={() => handleBuyNow(item)}
                className="fs-14 bg-gold-500 text-500 font-semibold rounded-full px-4 py-2 w-full mt-2"
              >
                {getLanguageFile.buy_now}
              </button>
            </div>
          ))}
        </section>
      );
    } else if (activeTab === "tokenHistory") {
      return (
        <section>
          <h2 className="fs-16 font-bold mb-4">
            {getLanguageFile.token_history}
          </h2>
          {tokenHistory?.map((item, key) => (
            <div key={key} className="bg-white rounded-lg shadow-md p-4 mb-2">
              <div className="flex justify-between items-center">
                <p className="fs-12 font-semibold text-500">{item?.date}</p>
                <p className="fs-11 font-normal text-500">{item?.type}</p>
              </div>
              <div className="flex justify-between items-center">
                <p className="fs-11 font-normal text-500">{item?.time}</p>
                <div className="flex items-center">
                  <span className="w-[22px] h-[22px] flex items-center justify-center p-1 bg-gold-500 rounded-full mx-1">
                    <StarIcon id="StarIcon" className=" text-white" />
                  </span>
                  <p className="fs-12 text-gold-900 font-semibold ml-1">
                    {item?.token} {getLanguageFile.tokens}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </section>
      );
    }
  };

  return (
    <>
      <Header
        changeLanguageClick={changeLanguageData}
        getLanguageFile={getLanguageFile}
      />

      <div id="body" className="bottombar" style={{ overflow: "hidden auto" }}>
        <div className="container p-lr-16 cursor-default">
          {/* Tokens Header */}
          <section className="flex flex-col gap-2 mb-4">
            <h1 className="fs-16 font-bold">{getLanguageFile.tokens}</h1>
            <p className="text-300 fs-12">
              {getLanguageFile.exchange_ticket_get_free_products}
            </p>
          </section>

          {/* My Tokens Section */}
          <section className="bg-gold-500 rounded-lg p-4 mb-4">
            <div className="flex justify-between items-center">
              <span className="fs-14 font-bold text-500">
                {getLanguageFile.my_token}
              </span>
              <div className="flex items-center gap-1 bg-gold-50 text-gold-500 font-semibold rounded-full px-3 py-1">
                <span className="fs-14 text-gold-900">
                  {profile?.token_point ? profile?.token_point : 0}
                </span>
                <span className="flex items-center justify-center p-1 bg-gold-500 rounded-full">
                  <StarIcon
                    id="StarIcon"
                    className="w-[12px] h-[12px] text-white"
                  />
                </span>
              </div>
            </div>
          </section>

          <div className="border-t-2 border-Light/active m-tb-24"></div>

          {/* Tab Navigation */}
          <section className="flex gap-2 mb-4">
            <button
              className={`text-500 fs-12 font-semibold px-2 py-2 border-[1px] rounded-full ${
                activeTab === "getToken"
                  ? "border-foundation-grey-normal-hover"
                  : "border-Light/active"
              }`}
              onClick={() => setActiveTab("getToken")}
            >
              {getLanguageFile.get_token}
            </button>
            {AccessToken && (
              <button
                className={`text-500 fs-12 font-semibold px-2 py-2 border-[1px] rounded-full ${
                  activeTab === "tokenHistory"
                    ? "border-foundation-grey-normal-hover"
                    : "border-Light/active"
                }`}
                onClick={() => setActiveTab("tokenHistory")}
              >
                {getLanguageFile.token_history}
              </button>
            )}
          </section>

          {/* Render Content Based on Active Tab */}
          {renderContent()}
          {tokenProductLoading && (
            <div className="flex justify-center items-center mt-4">
              <ClipLoader color="#FED801" size={13} />
            </div>
          )}
          <div ref={observerRef} className="h-8" />
        </div>
      </div>

      <BottomNav active={2} getLanguageFile={getLanguageFile} />
    </>
  );
}
