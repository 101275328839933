import React from "react";
import { Link, useParams } from "react-router-dom";
import { Bars3Icon, StarIcon } from "@heroicons/react/24/solid";
import { ShoppingBagIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

export default function AuthHeader({
  title,
  backkey = false,
  route = null,
  navigateFunc = null,
}) {
  let navigate = useNavigate();

  return (
    <>
      <header
        id="Top_Navbar"
        className="flex p-tb-9 p-lr-16 flex-col self-stretch shadow-md cursor-default"
      >
        <nav
          className={`${
            backkey ? "grid grid-cols-3" : "flex justify-between"
          } items-center`}
        >
          <div className="">
            {backkey && (
              <button
                onClick={() =>
                  route
                    ? navigate(route)
                    : navigateFunc
                    ? navigateFunc()
                    : navigate(-1)
                }
              >
                <ArrowLeftIcon className="flex w-[20px] h-[20px] justify-center items-center shrink-0 text-500" />
              </button>
            )}
          </div>
          <div className="fs-16 text-500 font-bold text-center leading-[24px] whitespace-nowrap">
            {title}
          </div>
          <div className=""></div>
        </nav>
      </header>
    </>
  );
}
