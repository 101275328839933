import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Header from "../../layout/Header";
import BottomNav from "../../layout/BottomNav";
import { StarIcon } from "@heroicons/react/24/solid";
import productImage1 from "../../assets/images/LuckydrawItem1.jpg";
import { useNavigate } from "react-router-dom";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";
import AlertPopup from "../../components/AlertPopUp/AlertPopUp";
import ClaimReferralGiftModal from "../LuckyDraw/ClaimReferralGiftModal";
import ConfirmReferralGiftModal from "../LuckyDraw/ConfirmReferralGiftModal";
import InviteMore from "./InviteMore";
import { dispatchStore } from "../../lib/dispatchStore";
import { referralGiftSlice } from "../../helper/customSlice";
import { ClipLoader } from "react-spinners";

export default function ReferralGift() {
  const navigate = useNavigate();
  const observerRef = useRef(null);

  const [changeLanguage, setChangeLanguage] = useState(
    localStorage.getItem("currentLanguage") || "en"
  );
  const [activeTab, setActiveTab] = useState("getToken");
  const [showConfirmTicketModal, setShowConfirmTicketModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [tokenNotEnoughError, setTokenNotEnoughError] = useState(null);
  const [serverError, setServerError] = useState(null);

  const AccessToken = useSelector((state) => state.AccessToken);

  const changeLanguageData = (languageType) => {
    setChangeLanguage(languageType);
  };

  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);
  const Listpage = useSelector((state) => state?.referralGiftSlice?.Listpage);
  const referralGift = useSelector((state) => state?.referralGiftSlice);
  const referralGiftLoading = useSelector(
    (state) => state.loading["referralGiftLoading"]
  );
  const reachEnd = useSelector((state) => state?.referralGiftSlice?.reachEnd);

  const tokenHistory = useSelector(
    (state) => state?.history?.token?.data?.data
  );
  const profile = useSelector((state) => state?.profile?.profile?.data?.data);

  function handleBuy() {
    if (AccessToken) {
      if (selectedProduct?.referral_point > profile?.referral_point) {
        setTokenNotEnoughError("Invite More people");
      } else {
        callApi("products/claimReferralGift")
          .withBody({
            product_id: selectedProduct?.id,
          })
          .loadingGroup("claimReferralGift")
          .execute()
          .then((res) => {
            if (res?.status_code == 200) {
              closeConfirmTicketModal();
            } else {
              setServerError(res?.message || "Unknown Server Error");
            }
            callApi("profile/profile").executeDispatch();
          });
      }
    } else {
      navigate("/signin");
    }
  }

  const closeConfirmTicketModal = () => {
    setShowConfirmTicketModal(false);
  };

  const handleCardClick = (product) => {
    setSelectedProduct(product);
    setShowConfirmTicketModal(true);
  };

  const fetchItems = async (page) => {
    if (reachEnd == false && page > 0) {
      callApi("products/referralGift")
        .withKeyParameter({
          page: page,
          perpage: referralGift?.per_page ? referralGift?.per_page : 20,
        })
        .loadingGroup(
          page == 1 ? "centerLoadReferralGift" : "referralGiftLoading"
        )
        .execute()
        .then((res) => {
          if (res?.data?.data?.length > 0) {
            // setTotalLength(res?.total_products)
            if (page == 1) {
              dispatchStore(
                referralGiftSlice.actions.setReferralGiftListing(res)
              );
            } else {
              dispatchStore(
                referralGiftSlice.actions.updateReferralGiftListing(res)
              );
            }
          }
        });
    }
  };

  useEffect(() => {
    console.log(observerRef.current, "observerRef.current");
    const observer = new IntersectionObserver(
      (entries) => {
        console.log(entries[0].isIntersecting, "entries[0].isIntersecting");
        if (entries[0].isIntersecting) {
          dispatchStore(referralGiftSlice.actions.plusListpage());
        }
      },
      { root: document.querySelector("#body"), threshold: 0.01 }
    );
    if (observerRef.current) {
      observer.observe(observerRef.current);
    }
    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    fetchItems(Listpage);
  }, [Listpage]);

  useEffect(() => {
    localStorage.setItem("currentLanguage", changeLanguage);
  }, [changeLanguage]);

  useEffect(() => {
    if (activeTab == "tokenHistory") {
      callApi("history/token").executeDispatch();
    }
  }, [activeTab]);

  const renderContent = () => {
    if (activeTab === "getToken") {
      return (
        <section className="grid grid-cols-2 gap-4">
          {referralGift?.list?.map((item, key) => (
            <div key={key} className="bg-white rounded-lg shadow-md p-4">
              <button
                onClick={() => handleCardClick(item)}
                // onClick={() => navigate(`/product-detail?id=${item?.slug}`)}
              >
                <img
                  src={
                    item?.feature_image ? item?.feature_image : productImage1
                  }
                  alt="Gift Box"
                  className="w-full rounded-lg mb-4"
                />
                <h3 className="fs-11 font-normal text-400 mb-1 text-start">
                  {item?.name}
                </h3>
                <span className="flex items-center mb-1">
                  <p className="fs-11 font-bold text-500">MMK</p>
                  <p className="fs-16 font-extrabold text-500">
                    {item?.actual_price}
                  </p>
                </span>
                <span className="flex items-center mb-1">
                  <span className="w-[22px] h-[22px] flex items-center justify-center p-1 bg-gold-500 rounded-full mx-1">
                    <StarIcon id="StarIcon" className=" text-white" />
                  </span>
                  <span className="fs-14 font-bold text-gold-900">
                    {item?.referral_point} Referral
                  </span>
                </span>
              </button>

              <button
                onClick={() => handleCardClick(item)}
                className="fs-14 bg-gold-500 text-500 font-semibold rounded-full px-4 py-2 w-full mt-2"
              >
                {getLanguageFile.claim}
              </button>
            </div>
          ))}
        </section>
      );
    } else if (activeTab === "tokenHistory") {
      return (
        <section>
          <h2 className="fs-16 font-bold mb-4">
            {getLanguageFile.token_history}
          </h2>
          {tokenHistory?.map((item, key) => (
            <div key={key} className="bg-white rounded-lg shadow-md p-4 mb-2">
              <div className="flex justify-between items-center">
                <p className="fs-12 font-semibold text-500">{item?.date}</p>
                <p className="fs-11 font-normal text-500">{item?.type}</p>
              </div>
              <div className="flex justify-between items-center">
                <p className="fs-11 font-normal text-500">{item?.time}</p>
                <div className="flex items-center">
                  <span className="w-[22px] h-[22px] flex items-center justify-center p-1 bg-gold-500 rounded-full mx-1">
                    <StarIcon id="StarIcon" className=" text-white" />
                  </span>
                  <p className="fs-12 text-gold-900 font-semibold ml-1">
                    {item?.token} {getLanguageFile.tokens}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </section>
      );
    }
  };

  return (
    <>
      {tokenNotEnoughError && (
        <InviteMore
          btnLabel={"Invite More People"}
          text={getLanguageFile.people_inviting}
          onClick={() => navigate("/signup?ref=" + profile?.referral_code)}
          cancel={getLanguageFile.propbox_ok}
          close={() => setTokenNotEnoughError(null)}
        />
      )}

      {serverError && (
        <AlertPopup
          type={"error"}
          btnLabel={getLanguageFile.propbox_ok}
          text={serverError}
          onClick={() => setServerError(null)}
        />
      )}

      <Header
        changeLanguageClick={changeLanguageData}
        getLanguageFile={getLanguageFile}
      />

      <div id="body" className="bottombar" style={{ overflow: "hidden auto" }}>
        <div className="container p-lr-16 cursor-default">
          {/* My Tokens Section */}
          <section className="bg-gold-500 rounded-lg p-4 mb-4">
            <div className="flex justify-between items-center">
              <span className="fs-14 font-bold text-500">{getLanguageFile.my_referral}</span>
              <div className="flex items-center gap-1 bg-gold-50 text-gold-500 font-semibold rounded-full px-3 py-1">
                <span className="fs-14 text-gold-900">
                  {profile?.referral_point ? profile?.referral_point : 0}
                </span>
                <span className="flex items-center justify-center p-1 bg-gold-500 rounded-full">
                  <StarIcon
                    id="StarIcon"
                    className="w-[12px] h-[12px] text-white"
                  />
                </span>
              </div>
            </div>
          </section>

          <div className="border-t-2 border-Light/active m-tb-24"></div>

          {/* Render Content Based on Active Tab */}
          {renderContent()}
          {referralGiftLoading && (
            <div className="flex justify-center items-center mt-4">
              <ClipLoader color="#FED801" size={13} />
            </div>
          )}
          <div ref={observerRef} className="h-8" />
        </div>
      </div>

      <BottomNav getLanguageFile={getLanguageFile} />

      <ConfirmReferralGiftModal
        show={showConfirmTicketModal}
        name={selectedProduct?.name}
        onClose={closeConfirmTicketModal}
        onConfirm={() => {
          // openPaymentMethodModal();
          handleBuy();
        }}
      />
    </>
  );
}
