// import React, { useState, useEffect, useRef, act } from "react";
// import { Link, useFetcher, useLocation, useNavigate } from "react-router-dom";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import Header from "../../layout/Header";
// import BottomNav from "../../layout/BottomNav";
// import { StarIcon } from "@heroicons/react/24/solid";
// import LuckydrawItem1 from "../../assets/images/LuckydrawItem1.jpg";
// import { useSelector } from "react-redux";
// import BuyTicketModal from "./BuyTicketModal";
// import ConfirmTicketModal from "./ConfirmTicketModal";
// import callApi from "../../services/api/apiClient";
// import AlertPopup from "../../components/AlertPopUp/AlertPopUp";
// import PaymentMethodModal from "./PaymentMethodModal";
// import { slice } from "../../redux/reducers/reducer";
// import { dispatchStore } from "../../lib/dispatchStore";
// import { useCallback } from "react";
// import { ticketProductSlice } from "../../helper/customSlice";
// import { BeatLoader, CircleLoader } from "react-spinners";

// export default function LuckyDrawAllPrize() {
//   let navigate = useNavigate();
//   const location = useLocation();
//   const { data, showBuyTicketModal: initialShowModal, selectedProduct: initialProduct, buytokenid } = location.state || {};
//   const fileInputRef = useRef(null);
//   const observerRef = useRef(null);

//   const [changeLanguage, setChangeLanguage] = useState(
//     localStorage.getItem("currentLanguage") || "en"
//   );
//   const [activeTab, setActiveTab] = useState(data || "allPrizes");
//   const [showBuyTicketModal, setShowBuyTicketModal] = useState(false);
//   const [showConfirmTicketModal, setShowConfirmTicketModal] = useState(false);
//   const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
//   const [selectedProduct, setSelectedProduct] = useState(null);
//   const changeLanguageData = (languageType) => {
//     setChangeLanguage(languageType);
//   };
//   const Listpage = useSelector((state) => state?.ticketProductSlice?.Listpage);
//   const ticketProduct = useSelector((state) => state?.ticketProductSlice);
//   const ticketProductLoading = useSelector(
//     (state) => state.loading["ticketProductLoading"]
//   );
//   const reachEnd = useSelector((state) => state?.ticketProductSlice?.reachEnd);

//   const AccessToken = useSelector((state) => state.AccessToken);

//   const [serverError, setServerError] = useState(null);
//   const [tokenNotEnoughError, setTokenNotEnoughError] = useState(null);
//   const [claimSuccess, setClaimSuccess] = useState(null);

//   const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);

//   const [buyAmount, setBuyAmount] = useState(1);
//   const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
//   const [useCardNextTime, setUseCardNextTime] = useState(false);
//   const [paymentImage, setPaymentImage] = useState(null);

//   useEffect(() => {
//     if (initialProduct && initialShowModal) {
//       setSelectedProduct(initialProduct);
//       setShowBuyTicketModal(true);
//     }
//   }, []);

//   async function handleBuy(product) {
//     // if (selectedPaymentOption) {
//     //   if (paymentImage) {
//     //     const formData = new FormData();
//     //     formData.append("product_id", selectedProduct?.id);
//     //     formData.append("payment_id", selectedPaymentOption);
//     //     formData.append("ticket_count", buyAmount);
//     //     formData.append("payment_screenshot", paymentImage);
//     //     callApi("cart/buyticket")
//     //       .withBody(formData)
//     //       .executeDispatch()
//     //       .then((res) => {
//     //         if (res?.status_code == 200) {
//     //           navigate("/ticket-buy-success", { state: { data: res } });
//     //         } else {
//     //           setServerError(res?.message || "Unknown Server Error");
//     //         }
//     //       });
//     //   }
//     //   else{
//     //     setServerError("Please select Payment Image");
//     //   }
//     // } else {
//     //   setServerError("Please select Payment");
//     // }

//     const formData = new FormData();
//     formData.append("product_id", selectedProduct?.id);
//     formData.append("ticket_count", buyAmount);
//     callApi("cart/buyticket")
//       .withBody(formData)
//       .loadingGroup("cart/buyticket")
//       .executeDispatch()
//       .then((res) => {
//         if (res?.status_code == 200) {
//           closeConfirmTicketModal();
//           navigate("/ticket-buy-success", { state: { data: res } });
//         } else {
//           setServerError(res?.message || "Unknown Server Error");
//         }
//       });
//   }

//   const handleFileChange = async (event) => {
//     const file = event.target.files[0];
//     if (!file) {
//       // Store the selected file in a ref
//       setServerError("Please select Payment Image");
//       return;
//     }
//     setPaymentImage(file);
//   };

//   const handleCardClick = (product) => {
//     setSelectedProduct(product);
//     setShowBuyTicketModal(true);
//   };

//   const openConfirmTicketModal = () => {
//     if (AccessToken) {
//       setShowConfirmTicketModal(true);
//     } else {
//       navigate("/signin");
//     }
//   };

//   const closeConfirmTicketModal = () => {
//     setShowConfirmTicketModal(false);
//   };

//   const openPaymentMethodModal = () => {
//     setShowPaymentMethodModal(true);
//   };

//   const editTicketModal = () => {
//     setShowConfirmTicketModal(false);
//     setShowBuyTicketModal(true);
//   };

//   const editConfirmTicketModal = () => {
//     setShowPaymentMethodModal(false);
//     setShowConfirmTicketModal(true);
//   };

//   const handleClaimPrize = (clainID) => {
//     if (clainID) {
//       callApi("luckydraw/clainPrices")
//         .withBody({ lucky_draw_winner_id: clainID })
//         .executeDispatch()
//         .then((res) => {
//           setClaimSuccess("Prize claiming success");
//         });
//     }
//   };

//   const fetchItems = async (page) => {
//     if (reachEnd == false && page > 0) {
//       callApi("products/ticket")
//         .withKeyParameter({
//           page: page,
//           perpage: ticketProduct?.per_page ? ticketProduct?.per_page : 20,
//         })
//         .loadingGroup(
//           page == 1 ? "centerLoadTicketProduct" : "ticketProductLoading"
//         )
//         .execute()
//         .then((res) => {
//           if (res?.data?.data?.length > 0) {
//             // setTotalLength(res?.total_products)
//             if (page == 1) {
//               dispatchStore(
//                 ticketProductSlice.actions.setTicketProductListing(res)
//               );
//             } else {
//               dispatchStore(
//                 ticketProductSlice.actions.updateTicketProductListing(res)
//               );
//             }
//           }
//         });
//     }
//   };

//   useEffect(() => {
//     console.log(observerRef.current, "observerRef.current");
//     const observer = new IntersectionObserver(
//       (entries) => {
//         console.log(entries[0].isIntersecting, "entries[0].isIntersecting");
//         if (entries[0].isIntersecting) {
//           dispatchStore(ticketProductSlice.actions.plusListpage());
//         }
//       },
//       { root: document.querySelector("#body"), threshold: 0.01 }
//     );
//     if (observerRef.current) {
//       observer.observe(observerRef.current);
//     }
//     return () => {
//       if (observerRef.current) {
//         observer.unobserve(observerRef.current);
//       }
//     };
//   }, []);

//   useEffect(() => {
//     fetchItems(Listpage);
//   }, [Listpage]);

//   useEffect(() => {
//     localStorage.setItem("currentLanguage", changeLanguage);
//   }, [changeLanguage]);

//   useEffect(() => {
//     if (AccessToken) {
//       callApi("history/luckydraw").executeDispatch();
//     }
//     if (activeTab == "allPrizes") {
//     } else if (activeTab == "history") {
//     } else if (activeTab == "myPrizes") {
//       callApi("luckydraw/myPrices").executeDispatch();
//     }
//   }, [activeTab]);

//   useEffect(() => {
//     if (buytokenid) {
//       if (ticketProduct?.list) {
//         const findItem = ticketProduct?.list?.find(
//           (item) => item?.id == buytokenid
//         );
//         handleCardClick(findItem);
//       }
//     }
//   }, [buytokenid, ticketProduct]);

//   useEffect(() => {
//     dispatchStore(slice?.cart?.actions.clearSliceElement("paymentdetail"));
//   }, []);

//   const renderContent = () => {
//     let displayList = [];

//     if(ticketProduct?.list) {
//       const remainingItems = data ? ticketProduct.list.filter(item => item.id !== data.id) : ticketProduct.list;
//       displayList = [...displayList, ...remainingItems];
//     } else if (data && typeof data === "object") {
//       displayList.push(data)
//     }

//     return displayList.map((item, key) => {
//       return (
//         <div
//           key={key}
//           className="flex flex-col flex-wrap mb-4 p-lr-16 p-tb-14 bg-foundation-grey-light-hover border-[1px] border-gray-300 rounded-lg"
//         >
//           <button
//             onClick={() => handleCardClick(item)}
//             // onClick={() => navigate(`/product-detail?id=${item?.slug}`)}
//             className="flex h-[max-content]"
//           >
//             <div className="w-1/3 h-[max-content]">
//               <img
//                 src={item?.feature_image ? item?.feature_image : LuckydrawItem1}
//                 alt="Slide 1"
//                 className="w-full rounded-md"
//               />
//             </div>
//             <div className="w-2/3 p-lr-16 p-tb-8 flex flex-col text-start relative">
//               <span className="fs-14 font-semibold">{item?.name}</span>
//               <span className="fs-11">
//                 {getLanguageFile?.batch}
//                 {item?.batch}
//               </span>
//               <span className="fs-11">{item?.label}</span>
//               <span className="fs-11">{getLanguageFile.original_price}</span>
//               <span className="fs-11 font-semibold">
//                 MMK {item?.actual_price}
//               </span>
//               {/* <button onClick={() => navigate('/lucky-draw-live')} className="fs-11 text-left text-red-500 underline">View Live</button> */}
//               {/* <div className="absolute top-0 right-0 flex items-center justify-between bg-red-500 text-white font-semibold rounded-full p-lr-8 m-l-4">
//                 <span className="text-white fs-8 font-medium">
//                   2 {getLanguageFile.day_left}
//                 </span>
//               </div> */}
//             </div>
//           </button>
//           <div className="border-t border-gray-300 my-4"></div>
//           <div className="flex justify-between items-center">
//             <div className="flex flex-col gap-1 w-1/2">
//               <span className="text-gray-600 fs-11">
//                 {getLanguageFile.remaining_tickets} :{" "}
//                 <span className="text-customGray">
//                   {item?.remaining_tickets}
//                 </span>{" "}
//                 / {item?.ticket}
//               </span>
//               <div className="w-full bg-gray-300 h-3 rounded-full">
//                 <div
//                   className="bg-yellow-400 h-3 rounded-full"
//                   style={{
//                     width: `${
//                       (item?.remaining_tickets / item?.ticket) * 100 + "%"
//                     }`,
//                   }}
//                 ></div>
//               </div>
//             </div>
//             <button
//               onClick={() => handleCardClick(item)}
//               className="w-[128px] h-[32px] flex items-center p-lr-16 bg-yellow-400 rounded-full transition-none"
//             >
//               <span className="flex items-center justify-center p-1 bg-gold-500 rounded-full mr-2">
//                 <StarIcon
//                   id="StarIcon"
//                   className="w-[12px] h-[12px] text-white"
//                 />
//               </span>
//               <span className="fs-12 font-semibold">
//                 {item?.cost_token_point}{" "}
//                 {item?.cost_token_point > 1
//                   ? getLanguageFile.tokens
//                   : getLanguageFile.token}
//               </span>
//             </button>
//           </div>
//         </div>
//       );
//     });
//   };

//   return (
//     <>
//       {serverError && (
//         <AlertPopup
//           type={"error"}
//           btnLabel={getLanguageFile.propbox_ok}
//           text={serverError}
//           onClick={() => setServerError(null)}
//         />
//       )}

//       {tokenNotEnoughError && (
//         <AlertPopup
//           type={"warning"}
//           btnLabel={getLanguageFile.propbox_buy_token}
//           text={tokenNotEnoughError}
//           onClick={() => navigate("/token")}
//           cancel={getLanguageFile.propbox_ok}
//           close={() => setTokenNotEnoughError(null)}
//         />
//       )}

//       {claimSuccess && (
//         <AlertPopup
//           type={"success"}
//           btnLabel={"Go to my orders"}
//           text={claimSuccess}
//           onClick={() => navigate("/my-orders-to-ship")}
//           cancel={"Ok"}
//           close={() => setClaimSuccess(null)}
//         />
//       )}
//       <Header
//         changeLanguageClick={changeLanguageData}
//         getLanguageFile={getLanguageFile}
//       />

//       <div id="body" className="bottombar" style={{ overflow: "hidden auto" }}>
//         <div className="container p-lr-16">
//           {/* Lucky Draw Header */}
//           <section className="flex flex-col gap-2 mb-4">
//             <div className="flex items-center">
//               <h1 className="fs-14 font-bold">{getLanguageFile.lucky_draws}</h1>
//             </div>
//             <p className="text-500 fs-12">
//               {getLanguageFile.try_ticket_buy_using_token}
//             </p>
//           </section>

//           {/* Render Content Based on Active Tab */}
//           {renderContent()}
//           {ticketProductLoading && (
//             <div className="flex justify-center items-center mt-4">
//               <CircleLoader color="#FED801" size={13} />
//             </div>
//           )}
//           <div ref={observerRef} className="h-8" />
//         </div>
//       </div>

//       <BottomNav getLanguageFile={getLanguageFile} />

//       {/* Modals */}
//       <BuyTicketModal
//         show={showBuyTicketModal}
//         onClose={() => setShowBuyTicketModal(false)}
//         product={selectedProduct}
//         onConfirm={openConfirmTicketModal}
//         buyAmount={buyAmount}
//         setBuyAmount={setBuyAmount}
//         setTokenNotEnoughError={setTokenNotEnoughError}
//       />

//       <ConfirmTicketModal
//         show={showConfirmTicketModal}
//         handleEdit={editTicketModal}
//         onClose={closeConfirmTicketModal}
//         ticketCount={buyAmount}
//         onConfirm={() => {
//           // openPaymentMethodModal();
//           handleBuy();
//         }}
//         tokenCount={selectedProduct?.cost_token_point * buyAmount}
//       />

//       <PaymentMethodModal
//         show={showPaymentMethodModal}
//         onClose={() => setShowPaymentMethodModal(false)}
//         handleEdit={editConfirmTicketModal}
//         selectedPaymentOption={selectedPaymentOption}
//         setSelectedPaymentOption={setSelectedPaymentOption}
//         useCardNextTime={useCardNextTime}
//         setUseCardNextTime={setUseCardNextTime}
//         handleBuy={handleBuy}
//         handleFileChange={handleFileChange}
//         fileInputRef={fileInputRef}
//         paymentImage={paymentImage}
//         loadingGroup="cart/buyticket"
//       />
//     </>
//   );
// }

import React, { useState, useEffect, useRef, act } from "react";
import { Link, useFetcher, useLocation, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Header from "../../layout/Header";
import BottomNav from "../../layout/BottomNav";
import { StarIcon } from "@heroicons/react/24/solid";
import LuckydrawItem1 from "../../assets/images/LuckydrawItem1.jpg";
import { useSelector } from "react-redux";
import BuyTicketModal from "./BuyTicketModal";
import ConfirmTicketModal from "./ConfirmTicketModal";
import callApi from "../../services/api/apiClient";
import AlertPopup from "../../components/AlertPopUp/AlertPopUp";
import PaymentMethodModal from "./PaymentMethodModal";
import { slice } from "../../redux/reducers/reducer";
import { dispatchStore } from "../../lib/dispatchStore";
import { useCallback } from "react";
import { ticketProductSlice } from "../../helper/customSlice";
import { ClipLoader } from "react-spinners";

export default function LuckyDrawAllPrize() {
  let navigate = useNavigate();
  const location = useLocation();
  const { data, showBuyTicketModal: initialShowModal, selectedProduct: initialProduct, buytokenid } = location.state || {};
  const fileInputRef = useRef(null);
  const observerRef = useRef(null);
  const [closedTriggers, setClosedTriggers] = useState({});
  const [changeLanguage, setChangeLanguage] = useState(
    localStorage.getItem("currentLanguage") || "en"
  );
  const [activeTab, setActiveTab] = useState(data || "allPrizes");
  const [showBuyTicketModal, setShowBuyTicketModal] = useState(false);
  const [showConfirmTicketModal, setShowConfirmTicketModal] = useState(false);
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const changeLanguageData = (languageType) => {
    setChangeLanguage(languageType);
  };
  const Listpage = useSelector((state) => state?.ticketProductSlice?.Listpage);
  const ticketProduct = useSelector((state) => state?.ticketProductSlice);
  const ticketProductLoading = useSelector(
    (state) => state.loading["ticketProductLoading"]
  );
  const reachEnd = useSelector((state) => state?.ticketProductSlice?.reachEnd);

  const AccessToken = useSelector((state) => state.AccessToken);

  const [serverError, setServerError] = useState(null);
  const [tokenNotEnoughError, setTokenNotEnoughError] = useState(null);
  const [claimSuccess, setClaimSuccess] = useState(null);

  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);

  const [buyAmount, setBuyAmount] = useState(1);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
  const [useCardNextTime, setUseCardNextTime] = useState(false);
  const [paymentImage, setPaymentImage] = useState(null);
  // Add these state variables
const [isLoadingMore, setIsLoadingMore] = useState(false);
const [isRefreshing, setIsRefreshing] = useState(false);
const [lastRefreshTime, setLastRefreshTime] = useState(Date.now());
const loadingRef = useRef(false);
const refreshTimeoutRef = useRef(null);

// Update fetchItems function
const fetchItems = async (page, isInitialLoad = false) => {
  if (!loadingRef.current && !reachEnd && page > 0) {
    try {
      loadingRef.current = true;
      setIsLoadingMore(true);
      
      const timestamp = Date.now();
      const response = await callApi("products/ticket")
        .withKeyParameter({
          page,
          perpage: 20,
          _: timestamp,
          noCache: Math.random()
        })
        .withHeaders({
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        })
        .loadingGroup(isInitialLoad ? "centerLoadTicketProduct" : "ticketProductLoading")
        .execute();

      if (response?.data?.data?.length > 0) {
        if (page === 1) {
          dispatchStore(ticketProductSlice.actions.setTicketProductListing(response));
        } else {
          dispatchStore(ticketProductSlice.actions.updateTicketProductListing(response));
          dispatchStore(ticketProductSlice.actions.plusListpage());
        }
      }

    } catch (error) {
      console.error("Error fetching items:", error);
    } finally {
      loadingRef.current = false;
      setIsLoadingMore(false);
    }
  }
};

// Update refreshData function
// Update refreshData for immediate refresh
const refreshData = async (force = false) => {
  if (!isRefreshing || force) {
    try {
      setIsRefreshing(true);
      const timestamp = Date.now();
      
      // Get total pages
      const totalPages = Math.ceil((ticketProduct?.TotalList || 0) / 20);
      
      // Create array of promises for all pages
      const refreshPromises = Array.from({ length: totalPages }, (_, index) => {
        const page = index + 1;
        return callApi("products/ticket")
          .withKeyParameter({
            page,
            perpage: 20,
            _: timestamp,
            forceRefresh: true,
            noCache: Math.random()
          })
          .withHeaders({
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0'
          })
          .execute();
      });

      // Execute all requests simultaneously
      const responses = await Promise.all(refreshPromises);
      
      // Process responses in order
      responses.forEach((response, index) => {
        if (response?.data?.data) {
          if (index === 0) {
            dispatchStore(ticketProductSlice.actions.setTicketProductListing(response));
          } else {
            dispatchStore(ticketProductSlice.actions.updateTicketProductListing(response));
          }
        }
      });
      
      setLastRefreshTime(Date.now());
    } finally {
      setIsRefreshing(false);
    }
  }
};

// Update post-purchase effect for immediate refresh
useEffect(() => {
  if (location.state?.fromPurchase && location.state?.purchaseTime) {
    // Immediate refresh
    refreshData(true);
    
    // Quick follow-up refreshes
    [50, 150, 300].forEach(delay => {
      setTimeout(() => refreshData(true), delay);
    });
  }
}, [location.state?.fromPurchase, location.state?.purchaseTime]);

// Update automatic refresh cycle
useEffect(() => {
  const startRefreshCycle = () => {
    if (refreshTimeoutRef.current) {
      clearTimeout(refreshTimeoutRef.current);
    }
    
    refreshTimeoutRef.current = setTimeout(() => {
      refreshData(true);
      startRefreshCycle();
    }, 500); // Refresh every 500ms
  };

  startRefreshCycle();

  return () => {
    if (refreshTimeoutRef.current) {
      clearTimeout(refreshTimeoutRef.current);
    }
  };
}, []);

// Add intersection observer
useEffect(() => {
  const options = {
    root: null,
    rootMargin: '20px',
    threshold: 0.1
  };

  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting && 
        !loadingRef.current && 
        !reachEnd && 
        !ticketProductLoading && 
        ticketProduct?.list?.length > 0) {
      const nextPage = (ticketProduct?.Listpage || 1) + 1;
      fetchItems(nextPage, false);
    }
  };

  const observer = new IntersectionObserver(handleObserver, options);

  if (observerRef.current) {
    observer.observe(observerRef.current);
  }

  return () => {
    if (observerRef.current) {
      observer.unobserve(observerRef.current);
    }
  };
}, [ticketProduct?.Listpage, reachEnd, ticketProductLoading, ticketProduct?.list?.length]);

  useEffect(() => {
    if (initialProduct && initialShowModal) {
      if (!closedTriggers[initialProduct.id]) {
        setSelectedProduct(initialProduct);
        setShowBuyTicketModal(true);
      }
    }
  }, [initialProduct, initialShowModal, closedTriggers]);

  async function handleBuy(product) {
    // if (selectedPaymentOption) {
    //   if (paymentImage) {
    //     const formData = new FormData();
    //     formData.append("product_id", selectedProduct?.id);
    //     formData.append("payment_id", selectedPaymentOption);
    //     formData.append("ticket_count", buyAmount);
    //     formData.append("payment_screenshot", paymentImage);
    //     callApi("cart/buyticket")
    //       .withBody(formData)
    //       .executeDispatch()
    //       .then((res) => {
    //         if (res?.status_code == 200) {
    //           navigate("/ticket-buy-success", { state: { data: res } });
    //         } else {
    //           setServerError(res?.message || "Unknown Server Error");
    //         }
    //       });
    //   }
    //   else{
    //     setServerError("Please select Payment Image");
    //   }
    // } else {
    //   setServerError("Please select Payment");
    // }

    const formData = new FormData();
    formData.append("product_id", selectedProduct?.id);
    formData.append("ticket_count", buyAmount);
    callApi("cart/buyticket")
      .withBody(formData)
      .loadingGroup("cart/buyticket")
      .executeDispatch()
      .then((res) => {
        if (res?.status_code == 200) {
          closeConfirmTicketModal();
          navigate("/ticket-buy-success", { state: { data: res } });
        } else {
          setServerError(res?.message || "Unknown Server Error");
        }
      });
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      // Store the selected file in a ref
      setServerError("Please select Payment Image");
      return;
    }
    setPaymentImage(file);
  };
  

  const handleCardClick = (product, isButtonClick = false) => {
    if (product && (isButtonClick || !closedTriggers[product.id])) {
      setSelectedProduct(product);
      setShowBuyTicketModal(true);
    }
  };

  const openConfirmTicketModal = () => {
    if (AccessToken) {
      setShowConfirmTicketModal(true);
    } else {
      navigate("/signin");
    }
  };

  const closeConfirmTicketModal = () => {
    setShowConfirmTicketModal(false);
    if (selectedProduct) {
      setClosedTriggers(prev => ({
        ...prev,
        [selectedProduct.id]: true
      }));
    }
  };

  const closeBuyTicketModal = () => {
    if (selectedProduct) {
      setClosedTriggers(prev => ({
        ...prev,
        [selectedProduct.id]: true
      }));
    }
    setShowBuyTicketModal(false);
  };

  const openPaymentMethodModal = () => {
    setShowPaymentMethodModal(true);
  };

  const editTicketModal = () => {
    setShowConfirmTicketModal(false);
    setShowBuyTicketModal(true);
  };

  const editConfirmTicketModal = () => {
    setShowPaymentMethodModal(false);
    setShowConfirmTicketModal(true);
  };

  const handleClaimPrize = (clainID) => {
    if (clainID) {
      callApi("luckydraw/clainPrices")
        .withBody({ lucky_draw_winner_id: clainID })
        .executeDispatch()
        .then((res) => {
          setClaimSuccess("Prize claiming success");
        });
    }
  };

  // const fetchItems = async (page) => {
  //   if (reachEnd == false && page > 0) {
  //     callApi("products/ticket")
  //       .withKeyParameter({
  //         page: page,
  //         perpage: ticketProduct?.per_page ? ticketProduct?.per_page : 20,
  //       })
  //       .loadingGroup(
  //         page == 1 ? "centerLoadTicketProduct" : "ticketProductLoading"
  //       )
  //       .execute()
  //       .then((res) => {
  //         if (res?.data?.data?.length > 0) {
  //           // setTotalLength(res?.total_products)
  //           if (page == 1) {
  //             dispatchStore(
  //               ticketProductSlice.actions.setTicketProductListing(res)
  //             );
  //           } else {
  //             dispatchStore(
  //               ticketProductSlice.actions.updateTicketProductListing(res)
  //             );
  //           }
  //         }
  //       });
  //   }
  // };

  useEffect(() => {
    console.log(observerRef.current, "observerRef.current");
    const observer = new IntersectionObserver(
      (entries) => {
        console.log(entries[0].isIntersecting, "entries[0].isIntersecting");
        if (entries[0].isIntersecting) {
          dispatchStore(ticketProductSlice.actions.plusListpage());
        }
      },
      { root: document.querySelector("#body"), threshold: 0.01 }
    );
    if (observerRef.current) {
      observer.observe(observerRef.current);
    }
    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    fetchItems(Listpage);
  }, [Listpage]);

  useEffect(() => {
    localStorage.setItem("currentLanguage", changeLanguage);
  }, [changeLanguage]);

  useEffect(() => {
    if (AccessToken) {
      callApi("history/luckydraw").executeDispatch();
    }
    if (activeTab == "allPrizes") {
    } else if (activeTab == "history") {
    } else if (activeTab == "myPrizes") {
      callApi("luckydraw/myPrices").executeDispatch();
    }
  }, [activeTab]);

  useEffect(() => {
    if (buytokenid) {
      if (ticketProduct?.list) {
        const findItem = ticketProduct?.list?.find(
          (item) => item?.id == buytokenid
        );
        handleCardClick(findItem);
      }
    }
  }, [buytokenid, ticketProduct]);

  useEffect(() => {
    dispatchStore(slice?.cart?.actions.clearSliceElement("paymentdetail"));
  }, []);

  const renderContent = () => {
    let displayList = [];

    if(ticketProduct?.list) {
      const remainingItems = data ? ticketProduct.list.filter(item => item.id !== data.id) : ticketProduct.list;
      displayList = [...displayList, ...remainingItems];
    } else if (data && typeof data === "object") {
      displayList.push(data)
    }

    return displayList.map((item, key) => {
      return (
        <div
          key={key}
          className="flex flex-col flex-wrap mb-4 p-lr-16 p-tb-14 bg-foundation-grey-light-hover border-[1px] border-gray-300 rounded-lg"
        >
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleCardClick(item, true)
            }
              
             }
            // onClick={() => navigate(`/product-detail?id=${item?.slug}`)}
            className="flex h-[max-content]"
          >
            <div className="w-1/3 h-[max-content]">
              <img
                src={item?.feature_image ? item?.feature_image : LuckydrawItem1}
                alt="Slide 1"
                className="w-full rounded-md"
              />
            </div>
            <div className="w-2/3 p-lr-16 p-tb-8 flex flex-col text-start relative">
              <span className="fs-14 font-semibold">{item?.name}</span>
              <span className="fs-11">
                {getLanguageFile?.batch}
                {item?.batch}
              </span>
              <span className="fs-11">{item?.label}</span>
              <span className="fs-11">{getLanguageFile.original_price}</span>
              <span className="fs-11 font-semibold">
                MMK {item?.actual_price}
              </span>
              {/* <button onClick={() => navigate('/lucky-draw-live')} className="fs-11 text-left text-red-500 underline">View Live</button> */}
              {/* <div className="absolute top-0 right-0 flex items-center justify-between bg-red-500 text-white font-semibold rounded-full p-lr-8 m-l-4">
                <span className="text-white fs-8 font-medium">
                  2 {getLanguageFile.day_left}
                </span>
              </div> */}
            </div>
          </button>
          <div className="border-t border-gray-300 my-4"></div>
          <div className="flex justify-between items-center">
            <div className="flex flex-col gap-1 w-1/2">
              <span className="text-gray-600 fs-11">
                {getLanguageFile.remaining_tickets} :{" "}
                <span className="text-customGray">
                  {item?.remaining_tickets}
                </span>{" "}
                / {item?.ticket}
              </span>
              <div className="w-full bg-gray-300 h-3 rounded-full">
                <div
                  className="bg-yellow-400 h-3 rounded-full"
                  style={{
                    width: `${
                      (item?.remaining_tickets / item?.ticket) * 100 + "%"
                    }`,
                  }}
                ></div>
              </div>
            </div>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleCardClick(item, true)}}
              className="w-[128px] h-[32px] flex items-center p-lr-16 bg-yellow-400 rounded-full transition-none"
            >
              <span className="flex items-center justify-center p-1 bg-gold-500 rounded-full mr-2">
                <StarIcon
                  id="StarIcon"
                  className="w-[12px] h-[12px] text-white"
                />
              </span>
              <span className="fs-12 font-semibold">
                {item?.cost_token_point}{" "}
                {item?.cost_token_point > 1
                  ? getLanguageFile.tokens
                  : getLanguageFile.token}
              </span>
            </button>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      {serverError && (
        <AlertPopup
          type={"error"}
          btnLabel={getLanguageFile.propbox_ok}
          text={serverError}
          onClick={() => setServerError(null)}
        />
      )}

      {tokenNotEnoughError && (
        <AlertPopup
          type={"warning"}
          btnLabel={getLanguageFile.propbox_buy_token}
          text={tokenNotEnoughError}
          onClick={() => navigate("/token")}
          cancel={getLanguageFile.propbox_ok}
          close={() => setTokenNotEnoughError(null)}
        />
      )}

      {claimSuccess && (
        <AlertPopup
          type={"success"}
          btnLabel={"Go to my orders"}
          text={claimSuccess}
          onClick={() => navigate("/my-orders-to-ship")}
          cancel={"Ok"}
          close={() => setClaimSuccess(null)}
        />
      )}
      <Header
        changeLanguageClick={changeLanguageData}
        getLanguageFile={getLanguageFile}
      />

      <div id="body" className="bottombar" style={{ overflow: "hidden auto" }}>
        <div className="container p-lr-16">
          {/* Lucky Draw Header */}
          <section className="flex flex-col gap-2 mb-4">
            <div className="flex items-center">
              <h1 className="fs-14 font-bold">{getLanguageFile.lucky_draws}</h1>
            </div>
            <p className="text-500 fs-12">
              {getLanguageFile.try_ticket_buy_using_token}
            </p>
          </section>

          {/* Render Content Based on Active Tab */}
          {renderContent()}
          {ticketProductLoading && (
            <div className="flex justify-center items-center mt-4">
              <ClipLoader color="#FED801" size={13} />
            </div>
          )}
          <div ref={observerRef} className="h-8" />
        </div>
      </div>

      <BottomNav getLanguageFile={getLanguageFile} />

      {/* Modals */}
      <BuyTicketModal
        show={showBuyTicketModal}
        onClose={closeBuyTicketModal}
        product={selectedProduct}
        onConfirm={openConfirmTicketModal}
        buyAmount={buyAmount}
        setBuyAmount={setBuyAmount}
        setTokenNotEnoughError={setTokenNotEnoughError}
      />

      <ConfirmTicketModal
        show={showConfirmTicketModal}
        handleEdit={editTicketModal}
        onClose={closeConfirmTicketModal}
        ticketCount={buyAmount}
        onConfirm={() => {
          // openPaymentMethodModal();
          handleBuy();
        }}
        tokenCount={selectedProduct?.cost_token_point * buyAmount}
      />

      <PaymentMethodModal
        show={showPaymentMethodModal}
        onClose={() => setShowPaymentMethodModal(false)}
        handleEdit={editConfirmTicketModal}
        selectedPaymentOption={selectedPaymentOption}
        setSelectedPaymentOption={setSelectedPaymentOption}
        useCardNextTime={useCardNextTime}
        setUseCardNextTime={setUseCardNextTime}
        handleBuy={handleBuy}
        handleFileChange={handleFileChange}
        fileInputRef={fileInputRef}
        paymentImage={paymentImage}
        loadingGroup="cart/buyticket"
      />
    </>
  );
}
