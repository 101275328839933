import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import Banner1 from "../../assets/images/banner1.png";
import LuckydrawItem1 from "../../assets/images/LuckydrawItem1.jpg";
import {
  GiftIcon,
  HomeIcon,
  ShoppingCartIcon,
  StarIcon,
  TicketIcon,
} from "@heroicons/react/24/solid";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import BottomNav from "../../layout/BottomNav";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";
import Header from "../../layout/Header";
import Marquee from "react-fast-marquee";
import PWAInstalllationAlert from "./PWAInstalllationAlert";
import AlertPopup from "../../components/AlertPopUp/AlertPopUp";
import DailyRewardModal from "../dailyreward/DailyRewardModal";
import { dispatchStore } from "../../lib/dispatchStore";
import { homeProductSlice } from "../../helper/customSlice";
import { ClipLoader } from "react-spinners";

export default function HomePage() {
  const observerRef = useRef(null);

  const navigate = useNavigate();

  const sliders = useSelector((state) => state?.home?.sliders?.data?.data);
  const homelatest = useSelector(
    (state) => state?.home?.homelatest?.data?.data
  );
  const profile = useSelector((state) => state?.profile?.profile?.data?.data);
  const winnerSlide = useSelector(
    (state) => state?.others?.winnerSlide?.data?.data
  );
  const AccessToken = useSelector((state) => state.AccessToken);
  const Listpage = useSelector((state) => state?.homeProductSlice?.Listpage);
  const homeProduct = useSelector((state) => state?.homeProductSlice);
  const homeProductLoading = useSelector(
    (state) => state.loading["homeProductLoading"]
  );
  const reachEnd = useSelector((state) => state?.homeProductSlice?.reachEnd);

  // Change language
  const [changeLanguage, setChangeLanguage] = useState(
    window.localStorage.currentLanguage || "en"
  );
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);

  const changeLanguageData = (languageType) => {
    setChangeLanguage(languageType);
    // localStorage.setItem("currentLanguage", languageType);
  };
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);

  function handleBuyTokenClick(item) {
    navigate("/lucky-draw-all-prize", { state: { buytokenid: item?.id } });
  }

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
          localStorage.setItem("pwaInstalled", "true"); // Mark PWA as installed
        } else {
          console.log("User dismissed the install prompt");
        }
        setDeferredPrompt(null);
        setShowInstallPrompt(false); // Hide the prompt
      });
    }
  };

  const fetchItems = async (page) => {
    if (reachEnd == false && page > 0) {
      callApi("home/homeproduct")
        .withHeaders({ "X-localization": window.localStorage.currentLanguage })
        .withKeyParameter({
          page: page,
          perpage: homeProduct?.per_page ? homeProduct?.per_page : 20,
        })
        .loadingGroup(
          page == 1 ? "centerLoadHomeProduct" : "homeProductLoading"
        )
        .execute()
        .then((res) => {
          if (res?.data?.data?.length > 0) {
            // setTotalLength(res?.total_products)
            if (page == 1) {
              dispatchStore(
                homeProductSlice.actions.setHomeProductListing(res)
              );
            } else {
              dispatchStore(
                homeProductSlice.actions.updateHomeProductListing(res)
              );
            }
          }
        });
    }
  };

  useEffect(() => {
    callApi("home/sliders").executeDispatch();
    callApi("home/homelatest")
      .withHeaders({ "X-localization": window.localStorage.currentLanguage })
      .executeDispatch();
    callApi("others/winnerSlide").executeDispatch();

    console.log(observerRef.current, "observerRef.current");
    const observer = new IntersectionObserver(
      (entries) => {
        console.log(entries[0].isIntersecting, "entries[0].isIntersecting");
        if (entries[0].isIntersecting) {
          dispatchStore(homeProductSlice.actions.plusListpage());
        }
      },
      { root: document.querySelector("#body"), threshold: 0.01 }
    );
    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    // Check if app is running as a PWA
    const isAppInstalled =
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone;
    if (!isAppInstalled && !localStorage.getItem("pwaInstalled")) {
      // Listen for beforeinstallprompt event
      const handleBeforeInstallPrompt = (e) => {
        e.preventDefault();
        // Save the event for later use
        setDeferredPrompt(e);
        // Show the custom install prompt
        setShowInstallPrompt(true);
      };
      window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
      return () => {
        window.removeEventListener(
          "beforeinstallprompt",
          handleBeforeInstallPrompt
        );
      };
    }
    
    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (AccessToken) {
      callApi("profile/profile").executeDispatch();
    }
  }, [AccessToken]);

  // useEffect(() => {
  //   //
  //   // localStorage.setItem("currentLanguage", changeLanguage);
  // }, [changeLanguage]);

  useEffect(() => {
    fetchItems(Listpage);
  }, [Listpage]);

  return (
    <>
      {showInstallPrompt && (
        <PWAInstalllationAlert
          type={"message"}
          text={"Would you like to install this app on your home screen?"}
          btnLabel={"Install"}
          onClick={() => {
            setShowInstallPrompt(false);
            handleInstallClick();
          }}
          cancel={getLanguageFile.cancel}
          close={() => setShowInstallPrompt(false)}
        />
      )}

      <Header
        changeLanguageClick={changeLanguageData}
        getLanguageFile={getLanguageFile}
      />

      <div id="body" className="bottombar" style={{ overflow: "hidden auto" }}>
        <section className="m-b-16">
          <Swiper
            modules={[Autoplay, Navigation, Pagination]}
            spaceBetween={0}
            slidesPerView={1}
            pagination={{ clickable: true }}
            className="shadow-lg overflow-hidden"
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
          >
            {sliders?.map((item, key) => (
              <SwiperSlide key={key}>
                <a href={item?.url} target="_blank">
                  <img src={item?.image} alt={item?.name} className="w-full" />
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        </section>

        <section className="m-b-24 p-lr-16 container">
          <div
            onClick={() => navigate("/prize-winners")}
            className="flex justify-between items-center bg-red-500 px-4 py-2 rounded-lg font-semibold text-white cursor-pointer"
          >
            <Marquee>
              {winnerSlide?.map((eachWinnerSlide, key) => (
                <div key={key}>
                  <span className="font-medium text-white fs-12 pe-2">
                    {eachWinnerSlide?.product} Winner
                  </span>
                  <span className="font-bold text-white fs-12 pe-[90px]">
                    {eachWinnerSlide?.name}
                  </span>
                </div>
              ))}
            </Marquee>

            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
          </div>
        </section>

        <section className="m-b-16 p-lr-16 container cursor-default">
          <div className="flex justify-between items-center">
            <span className="flex items-center fs-16">
              {getLanguageFile.homePageLuckyDraw}
            </span>

            <button
              onClick={() => navigate("lucky-draw-all-prize")}
              className="flex items-center fs-12"
            >
              {getLanguageFile.view_all}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-[16px] h-[16px]"
              >
                <path
                  fillRule="evenodd"
                  d="M3 10a.75.75 0 0 1 .75-.75h10.638L10.23 5.29a.75.75 0 1 1 1.04-1.08l5.5 5.25a.75.75 0 0 1 0 1.08l-5.5 5.25a.75.75 0 1 1-1.04-1.08l4.158-3.96H3.75A.75.75 0 0 1 3 10Z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </section>

        <section className="m-b-24 p-lr-16 container">
          <Swiper
            modules={[Navigation]}
            spaceBetween={10}
            slidesPerView={1.1}
            className="overflow-x-scroll no-scrollbar"
          >
            {homelatest?.map((item, key) => (
              <SwiperSlide key={key}>
                <div className="flex flex-col flex-wrap border-[1px] border-gray-300 bg-gray-200 p-lr-16 p-tb-14 rounded-lg">
                  <button
                    onClick={() => handleBuyTokenClick(item)}
                    // onClick={() => navigate(`/product-detail?id=${item?.slug}`)}
                    className="flex text-start"
                  >
                    <div className="w-1/3">
                      <span>
                        <img
                          src={
                            item?.feature_image
                              ? item?.feature_image
                              : LuckydrawItem1
                          }
                          alt="Slide 1"
                          className="rounded-md w-full"
                        />
                      </span>
                    </div>
                    <div className="flex flex-col p-lr-16 p-tb-8 w-2/3">
                      <span className="font-semibold fs-14">{item?.name}</span>
                      <span className="fs-11">{item?.category}</span>
                      <span className="font-semibold fs-11">
                        MMK {item?.actual_price}
                      </span>
                      {/* {item?.off_price > 0 && (
                        <span className="text-300 line-through leading-[16px] fs-10 me-1">
                          MMK {item?.off_price}
                        </span>
                      )} */}
                      {/* <a href={"/"} className="text-red-500 underline fs-11">
                        View Live
                      </a> */}
                    </div>
                  </button>

                  <div className="border-gray-300 my-4 border-t"></div>

                  <div className="flex justify-between items-center cursor-pointer">
                    <div className="w-1/2">
                      <span className="text-gray-600 fs-11">
                        {getLanguageFile.remaining_tickets_lower} :{" "}
                        <span className="text-customGray">
                          {item?.remaining_tickets}
                        </span>{" "}
                        / {item?.ticket}
                      </span>
                      <div className="bg-gray-300 rounded-full w-full h-3">
                        <div
                          className="bg-yellow-400 rounded-full h-3"
                          style={{
                            width: `${
                              (item?.remaining_tickets / item?.ticket) * 100
                            }%`,
                          }}
                        ></div>
                      </div>
                    </div>

                    <button
                      onClick={() =>
                        AccessToken
                          ? handleBuyTokenClick(item)
                          : navigate("/signin")
                      }
                      className="flex items-center bg-yellow-400 p-lr-16 p-tb-6 rounded-full w-[128px]"
                    >
                      <span className="flex gap-[10px] bg-white m-r-10 p-all-4 rounded-full">
                        <TicketIcon
                          id="StarIcon"
                          className="flex justify-center items-center w-[12px] h-[12px] text-yellow-400 shrink-0"
                        />
                      </span>
                      <span className="font-semibold fs-12">
                        {item?.cost_token_point}{" "}
                        {item?.cost_token_point > 1
                          ? getLanguageFile.tokens
                          : getLanguageFile.token}
                      </span>
                    </button>
                  </div>

                  <div className="right-2 absolute flex justify-between items-center bg-red-500 m-l-4 p-lr-8 rounded-full font-semibold text-white">
                    <span className="font-medium text-white fs-8">
                      {item?.label}
                    </span>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </section>

        <section className="m-b-16 p-lr-16 container cursor-default">
          <h1 className="m-b-16 font-bold fs-16">
            {getLanguageFile.browse_best_deal}
          </h1>

          <div className="grid grid-cols-2 gap-2">
            {homeProduct?.list?.map((item, key) => (
              <button
                onClick={() => navigate(`/product-detail?id=${item?.slug}`)}
                key={key}
                className="relative flex flex-col bg-foundation-grey-light-hover rounded-b-md text-start"
              >
                <img
                  src={
                    item?.feature_image ? item?.feature_image : LuckydrawItem1
                  }
                  alt="Slide 1"
                  className="rounded-t-md w-full"
                />

                <div className="p-lr-8 p-tb-16 w-full">
                  <div className="font-bold text-ellipsis whitespace-nowrap overflow-hidden fs-11">
                    {item?.name}
                  </div>
                  <div className="text-ellipsis whitespace-nowrap overflow-hidden fs-11">
                    {item?.category}
                  </div>

                  <span className="flex items-center leading-normal">
                    <span className="font-bold fs-11 me-1">MMK</span>
                    <span className="font-extrabold leading-[16px] fs-16">
                      {item?.actual_price}
                    </span>
                  </span>
                </div>
              </button>
            ))}
          </div>
          {homeProductLoading && (
              <div className="flex justify-center items-center mt-4">
                <ClipLoader color="#FED801" size={13} />
              </div>
            )}
            <div ref={observerRef} className="h-8" />
        </section>

        <div className="m-b-10"></div>
      </div>

      <BottomNav active={1} getLanguageFile={getLanguageFile} />
    </>
  );
}
