// import React, {useState} from 'react';
// import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
// import { useNavigate } from 'react-router-dom';

// const ConfirmTicketModal = ({ show, onClose, ticketCount, tokenCount, onConfirm, handleEdit}) => {
//   const navigate = useNavigate()

//   if (!show) return null;
//   const changeLanguage = (window.localStorage.currentLanguage) ? window.localStorage.currentLanguage : 'en';

//   const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);
  
//   return (
//     <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
//       <div className="bg-white w-[300px] p-6 rounded-lg relative">
//         <button onClick={onClose} className="absolute top-2 right-2">
//           <XMarkIcon className="w-5 h-5 text-gray-500" />
//         </button>
//         <div className="flex justify-center mb-4">
//           <InformationCircleIcon className="w-10 h-10 text-green-500" />
//         </div>
//         <h2 className="fs-14 font-bold mb-4 text-center">{getLanguageFile.confirm_ticket}</h2>
//         <div className="bg-gray-100 p-4 rounded-lg mb-4">
//           <div className="flex justify-between mb-2">
//             <p className="fs-12">{getLanguageFile.ticket_count}</p>
//             <p className="fs-12 font-semibold">{ticketCount}</p>
//           </div>
//           <div className="flex justify-between">
//             <p className="fs-12">{getLanguageFile.token_count}</p>
//             <p className="fs-12 font-semibold">{tokenCount} Tokens</p>
//           </div>
//         </div>
//         <div className="flex justify-between space-x-2 fs-14">
//           <button
//             onClick={handleEdit}
//             className="bg-white border-[1px] border-foundation-grey-normal-hover text-500 font-semibold rounded-full px-4 py-2 flex-1"
//           >
//             {getLanguageFile.edit}
//           </button>
//           <button onClick={() => {
//             onConfirm()
//           }
//           } className="bg-yellow-400 text-500 font-semibold rounded-full px-4 py-2 flex-1">
//             {getLanguageFile.confirm}
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ConfirmTicketModal;

import React, { useState } from 'react';
import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';

const ConfirmTicketModal = ({ show, onClose, ticketCount, tokenCount, onConfirm, handleEdit }) => {
  const navigate = useNavigate();
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  
  if (!show) return null;
  const changeLanguage = window.localStorage.currentLanguage || 'en';
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);

  const handleConfirm = async () => {
    if (isConfirmLoading) return; // Prevent multiple clicks
    
    setIsConfirmLoading(true);
    try {
      await onConfirm();
    } catch (error) {
      console.error("Error in confirmation:", error);
      setIsConfirmLoading(false); // Reset loading state on error
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white w-[300px] p-6 rounded-lg relative">
        <button 
          onClick={onClose} 
          className="absolute top-2 right-2"
          disabled={isConfirmLoading}
        >
          <XMarkIcon className="w-5 h-5 text-gray-500" />
        </button>
        <div className="flex justify-center mb-4">
          <InformationCircleIcon className="w-10 h-10 text-green-500" />
        </div>
        <h2 className="fs-14 font-bold mb-4 text-center">{getLanguageFile.confirm_ticket}</h2>
        <div className="bg-gray-100 p-4 rounded-lg mb-4">
          <div className="flex justify-between mb-2">
            <p className="fs-12">{getLanguageFile.ticket_count}</p>
            <p className="fs-12 font-semibold">{ticketCount}</p>
          </div>
          <div className="flex justify-between">
            <p className="fs-12">{getLanguageFile.token_count}</p>
            <p className="fs-12 font-semibold">{tokenCount} Tokens</p>
          </div>
        </div>
        <div className="flex justify-between space-x-2 fs-14">
          <button
            onClick={handleEdit}
            disabled={isConfirmLoading}
            className={`bg-white border-[1px] border-foundation-grey-normal-hover text-500 font-semibold rounded-full px-4 py-2 flex-1 ${
              isConfirmLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50'
            }`}
          >
            {getLanguageFile.edit}
          </button>
          <button
            onClick={handleConfirm}
            disabled={isConfirmLoading}
            className={`bg-yellow-400 text-500 font-semibold rounded-full px-4 py-2 flex-1 flex items-center justify-center ${
              isConfirmLoading ? 'opacity-75 cursor-not-allowed' : 'hover:bg-yellow-500'
            }`}
          >
            <div className="flex items-center justify-center gap-2">
              {isConfirmLoading && <BeatLoader size={8} color="#FFFFFF" margin={2} />}
              <span>{isConfirmLoading ? 'Loading...' : getLanguageFile.confirm}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmTicketModal;
