import { createSlice } from '@reduxjs/toolkit';
import _ from "lodash";

function convertProxyObjectToPojo(s) {
  return _.cloneDeep(s);
}

const INITIAL_STATE = {
  homeProductSlice: {
    list: [],
    reachEnd: false,
    Listpage: 0,
    TotalList: null,
  },
  ticketProductSlice: {
    list: [],
    reachEnd: false,
    Listpage: 0,
    TotalList: null,
  },
  tokenProductSlice: {
    list: [],
    reachEnd: false,
    Listpage: 0,
    TotalList: null,
  },
  referralGiftSlice:{
    list: [],
    reachEnd: false,
    Listpage: 0,
    TotalList: null,
  }
}

export const notiSlice = createSlice({
  name: 'notiSlice',
  initialState: [],
  reducers: {
    setNotiSlice: (state, action) => {
      if (state.length === 0) {
        return action.payload;
      } else {
        // Otherwise, filter out existing notifications and merge
        const existingIds = new Set(state.map(notification => notification.id));
        const newNotifications = action.payload.filter(notification => !existingIds.has(notification.id));
        state.push(...newNotifications);
      }
    },
  },
});

export const liveFetchBoolean = createSlice({
  name: 'liveFetchBoolean',
  initialState: 0,
  reducers: {
    setLiveFetchBoolean: (state, action) => {
      return state === 0 ? 1 : 0;
    },
  },
});


export const notiCountSlice = createSlice({
  name: 'notiCountSlice',
  initialState: 0,
  reducers: {
    plusNotiCount: (state, action) => {
      return state + 1;
    },
  },
});

export const dailySignInRewardSlice = createSlice({
  name: 'dailySignInRewardSlice',
  initialState: {
    showDailyRewardModal: false,
    SuccessfulDailyRewardSigninModal: false,
    FailDailyRewardSigninModal: false,
    PaymentSuccessModal: false,
    PaymentIsDone: false,
  },
  reducers: {
    setShowDailyRewardModal: (state, action) => {
      state.showDailyRewardModal = action?.payload
      return state;
    },
    setSuccessfulDailyRewardSigninModal: (state, action) => {
      state.SuccessfulDailyRewardSigninModal = action?.payload
      return state;
    },
    setFailDailyRewardSigninModal: (state, action) => {
      state.FailDailyRewardSigninModal = action?.payload
      return state;
    },
    setPaymentSuccessModal: (state, action) => {
      state.PaymentSuccessModal = action?.payload
      return state;
    },
    setPaymentIsDone: (state, action) => {
      state.PaymentIsDone = action?.payload
      return state;
    },
  },
});

export const homeProductSlice = createSlice({
  name: 'homeProduct',
  initialState: INITIAL_STATE?.homeProductSlice,
  reducers: {
    setHomeProductListing: (state, action) => {
      state.list = action.payload.data.data;
      state.TotalList = action.payload.data.meta.total;
      if (state.list.length == action.payload.data.meta.total) {
        state.reachEnd = true;
      } else {
        state.reachEnd = false;
      }
    },
    updateHomeProductListing: (state, action) => {
      let oldArr = convertProxyObjectToPojo(state.list);
      let newArr = action.payload.data.data;
      state.TotalList = action.payload.data.meta.total;
      const existingIds = new Set(oldArr.map((element) => element.id));

      const uniqueList = newArr.filter((element) => {
        if (existingIds.has(element.id)) {
          const existingIndex = oldArr.findIndex(
            (existingElement) => existingElement.id === element.id
          );
          oldArr[existingIndex] = element;
          return false;
        }
        return true;
      });
      const sortedArr = [...oldArr, ...uniqueList];
      state.list = sortedArr;
      if (state.list.length == action.payload.data.meta.total) {
        state.reachEnd = true;
      } else {
        state.reachEnd = false;
      }
    },
    setListpage: (state, action) => {
      state.Listpage = action?.payload;
    },
    plusListpage: (state, action) => {
      state.Listpage = state.Listpage + 1;
    },
    resetSelected: (state, action) => {
      let oldArr = convertProxyObjectToPojo(state);
      oldArr.selectedSortBy = null;
      return oldArr;
    },
    resetHomeProductListing: (state, action) => {
      return INITIAL_STATE.homeProductSlice;
    },
  },
});

export const ticketProductSlice = createSlice({
  name: 'ticketProduct',
  initialState: {
    ...INITIAL_STATE?.ticketProductSlice,
    list: [],
    Listpage: 1,
    reachEnd: false,
    TotalList: null,
    refreshCounter: 0,
    lastPurchaseId: null,
    itemPerPage: 20
  },
  reducers: {
    setTicketProductListing: (state, action) => {
      state.list = action.payload.data.data || [];
      state.itemPerPage = action.payload.data.meta.per_page;
      state.TotalList = action.payload.data.meta.total;
      state.reachEnd = state.list.length >= action.payload.data.meta.total;
      state.Listpage = 1;
    },
    updateTicketProductListing: (state, action) => {
      const newItems = action.payload.data.data || [];
      const currentPage = action.payload.data.meta.current_page;
      
      if (currentPage === 1) {
        // For first page, replace existing items
        state.list = newItems;
      } else {
        // For subsequent pages, merge items
        const existingMap = new Map(state.list.map(item => [item.id, item]));
        newItems.forEach(newItem => {
          existingMap.set(newItem.id, newItem);
        });
        state.list = Array.from(existingMap.values())
          .sort((a, b) => a.id - b.id);
      }
      
      state.TotalList = action.payload.data.meta.total;
      state.itemPerPage = action.payload.data.meta.per_page;
      state.reachEnd = state.list.length >= action.payload.data.meta.total;
    },
    setListpage: (state, action) => {
      state.Listpage = action?.payload;
    },
    plusListpage: (state, action) => {
      state.Listpage = state.Listpage + 1;
    },
    clearTicketProductListing: (state, action) => {
      const purchaseId = action?.payload?.purchaseId || Date.now();
      return {
        ...INITIAL_STATE.ticketProductSlice,
        list: [],
        Listpage: 1,
        reachEnd: false,
        TotalList: null,
        refreshCounter: state.refreshCounter + 1,
        lastPurchaseId: purchaseId,
        itemPerPage: 20
      };
    }
  },
});

export const tokenProductSlice = createSlice({
  name: 'tokenProduct',
  initialState: INITIAL_STATE?.tokenProductSlice,
  reducers: {
    setTokenProductListing: (state, action) => {
      state.list = action.payload.data.data;
      state.TotalList = action.payload.data.meta.total;
      if (state.list.length == action.payload.data.meta.total) {
        state.reachEnd = true;
      } else {
        state.reachEnd = false;
      }
    },
    updateTokenProductListing: (state, action) => {
      let oldArr = convertProxyObjectToPojo(state.list);
      let newArr = action.payload.data.data;
      state.TotalList = action.payload.data.meta.total;
      const existingIds = new Set(oldArr.map((element) => element.id));

      const uniqueList = newArr.filter((element) => {
        if (existingIds.has(element.id)) {
          const existingIndex = oldArr.findIndex(
            (existingElement) => existingElement.id === element.id
          );
          oldArr[existingIndex] = element;
          return false;
        }
        return true;
      });
      const sortedArr = [...oldArr, ...uniqueList];
      state.list = sortedArr;
      if (state.list.length == action.payload.data.meta.total) {
        state.reachEnd = true;
      } else {
        state.reachEnd = false;
      }
    },
    setListpage: (state, action) => {
      state.Listpage = action?.payload;
    },
    plusListpage: (state, action) => {
      state.Listpage = state.Listpage + 1;
    },
    resetSelected: (state, action) => {
      let oldArr = convertProxyObjectToPojo(state);
      oldArr.selectedSortBy = null;
      return oldArr;
    },
    resetTokenProductListing: (state, action) => {
      return INITIAL_STATE.tokenProductSlice;
    },
  },
});

export const referralGiftSlice = createSlice({
  name: 'tokenProduct',
  initialState: INITIAL_STATE?.referralGiftSlice,
  reducers: {
    setReferralGiftListing: (state, action) => {
      state.list = action.payload.data.data;
      state.TotalList = action.payload.data.meta.total;
      if (state.list.length == action.payload.data.meta.total) {
        state.reachEnd = true;
      } else {
        state.reachEnd = false;
      }
    },
    updateReferralGiftListing: (state, action) => {
      let oldArr = convertProxyObjectToPojo(state.list);
      let newArr = action.payload.data.data;
      state.TotalList = action.payload.data.meta.total;
      const existingIds = new Set(oldArr.map((element) => element.id));

      const uniqueList = newArr.filter((element) => {
        if (existingIds.has(element.id)) {
          const existingIndex = oldArr.findIndex(
            (existingElement) => existingElement.id === element.id
          );
          oldArr[existingIndex] = element;
          return false;
        }
        return true;
      });
      const sortedArr = [...oldArr, ...uniqueList];
      state.list = sortedArr;
      if (state.list.length == action.payload.data.meta.total) {
        state.reachEnd = true;
      } else {
        state.reachEnd = false;
      }
    },
    setListpage: (state, action) => {
      state.Listpage = action?.payload;
    },
    plusListpage: (state, action) => {
      state.Listpage = state.Listpage + 1;
    },
    resetSelected: (state, action) => {
      let oldArr = convertProxyObjectToPojo(state);
      oldArr.selectedSortBy = null;
      return oldArr;
    },
    resetReferralGiftListing: (state, action) => {
      return INITIAL_STATE.referralGiftSlice;
    },
  },
});


export default {
  notiSlice,
  notiCountSlice,
  liveFetchBoolean,
  dailySignInRewardSlice,
  homeProductSlice,
  ticketProductSlice,
  tokenProductSlice,
  referralGiftSlice,
}