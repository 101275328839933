import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../layout/Header";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import Banner1 from "../../assets/images/banner1.png";
import LuckydrawItem1 from "../../assets/images/LuckydrawItem1.jpg";
import Avatar from "../../assets/icons/Avatar.png";
import { StarIcon, ShoppingCartIcon } from "@heroicons/react/24/solid";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import LoadingComponent from "../../components/loading/LoadingComponent";
import AlertPopup from "../../components/AlertPopUp/AlertPopUp";
import ReviewModal from "./ReviewModal";

export default function ProductDetail() {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const product_id = params.get("id");

  const inputRef = useRef(null);
  const debounceTimeout = useRef(null);

  // Change language
  const [changeLanguage, setChangeLanguage] = useState(
    window.localStorage.currentLanguage || "en"
  );
  const changeLanguageData = (languageType) => {
    setChangeLanguage(languageType);
  };
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);
  const AccessToken = useSelector((state) => state.AccessToken);
  const productDetail = useSelector(
    (state) => state?.products?.productDetail?.data?.data
  );
  const allReview = useSelector((state) => state?.review?.getAll?.data?.data);
  const userReview = useSelector(
    (state) => state?.review?.getuserReview?.data?.data
  );

  const [seeReview, setSeeReview] = useState(false);
  const [buyAmount, setBuyAmount] = useState(1);
  const [alertmessage, setAlertmessage] = useState("");
  const [serverError, setServerError] = useState(null);
  const [reviewModalToggle, setReviewModalToggle] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    localStorage.setItem("currentLanguage", changeLanguage);
  }, [changeLanguage]);

  useEffect(() => {
    if (product_id) {
      callApi("products/productDetail")
        .withHeaders({ "X-localization": window.localStorage.currentLanguage })
        .withKeyParameter({ productSlug: product_id })
        .loadingGroup("productDetail")
        .executeDispatch();
    }
  }, [product_id]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = buyAmount;
    }
  }, [buyAmount]);

  async function handleAddtoCart() {
    callApi("cart/add")
      .withBody({
        product_id: productDetail?.id,
        qty: buyAmount,
      })
      .loadingGroup("addToCartLoading")
      .executeDispatch()
      .then((res) => {
        callApi("cart/list").executeDispatch();
        if (res?.status_code == 200) {
          setAlertmessage(
            buyAmount +
              " " +
              res?.data?.product?.name +
              " has been added to Cart"
          );
        }
      });
  }

  function BuyTicket() {
    navigate("/lucky-draw-all-prize", { 
      state: { 
        data: "allPrizes",
        showBuyTicketModal: true, 
        selectedProduct: productDetail, 
        buytokenid: productDetail?.id,
        fromProductDetail: true  // Add this flag to indicate we came from product detail
      } 
    });
  }

  function handleBuyNow() {
    navigate("/confirm-order", {
      state: { product: productDetail, qty: buyAmount },
    });
  }

  return (
    <>
      {alertmessage && (
        <AlertPopup
          type={"success"}
          btnLabel={getLanguageFile.propbox_ok}
          text={alertmessage}
          onClick={() => setAlertmessage(null)}
        />
      )}
      {serverError && (
        <AlertPopup
          type={"error"}
          btnLabel={"Ok"}
          text={serverError}
          onClick={() => setServerError(null)}
        />
      )}
      <Header
        changeLanguageClick={changeLanguageData}
        getLanguageFile={getLanguageFile}
      />
      <div
        id="body"
        className="bottombutton"
        style={{ overflow: "hidden auto", marginTop: "10px" }}
      >
        <LoadingComponent
          loadingGroup={"productDetail"}
          loadingDesign={
            <div className="flex justify-center content-center mt-5 w-100">
              <ClipLoader color="#fed801" size={20} />
            </div>
          }
        >
          <section className="m-b-24 cursor-default p-lr-16 container">
            <nav className="flex" aria-label="Breadcrumb">
              <ol className="flex space-x-2 fs-12">
                <li>
                  <button
                    onClick={() => navigate("/")}
                    className="text-gray-500 hover:text-red-500"
                  >
                    {getLanguageFile.home}
                  </button>
                </li>
                <li>
                  <span className="text-gray-500"> \ </span>
                </li>
                <li>
                  <button
                    onClick={() =>
                      navigate(
                        `/category?category_id=${productDetail?.category_id}`
                      )
                    }
                    className="text-gray-500 hover:text-red-500"
                  >
                    {productDetail?.category}
                  </button>
                </li>
                <li>
                  <span className="text-gray-500"> \ </span>
                </li>
                <li className="text-gray-500">{productDetail?.name}</li>
              </ol>
            </nav>
          </section>

          <section className="relative m-b-24 p-lr-16 container">
            <Swiper
              modules={[Navigation]}
              spaceBetween={8}
              slidesPerView={1.48}
              className="relative overflow-hidden cursor-pointer"
              onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
            >
              <SwiperSlide>
                <img
                  src={
                    productDetail?.feature_image
                      ? productDetail?.feature_image
                      : LuckydrawItem1
                  }
                  alt="Slide 1"
                  className="w-full"
                />
              </SwiperSlide>
              {productDetail?.product_images?.map((eachImage, key) => (
                <SwiperSlide key={key}>
                  <img src={eachImage} alt={key} className="w-full" />
                </SwiperSlide>
              ))}

              <div className="bottom-3 left-2 z-1 absolute flex justify-between items-center bg-white m-l-4 p-lr-8 p-tb-4 rounded-full font-semibold text-white">
                <span className="font-medium text-black fs-12">{currentSlide + 1} / {(productDetail?.product_images?.length || 0) + 1}</span>
              </div>
            </Swiper>
          </section>

          <section className="relative m-b-24 p-lr-16 container">
            <span className="flex cursor-default justify-between">
              <span className="flex items-center leading-normal">
                <span className="font-bold fs-16">MMK</span>
                <span className="font-extrabold leading-[32px] fs-24 me-2">
                  {productDetail?.actual_price}
                </span>

                {/* {productDetail?.off_price != 0 && (
                <>
                  <span className="flex items-center text-300 leading-normal">
                    <span className="fs-12">MMK</span>
                    <span className="line-through leading-[16px] fs-12">
                      {productDetail?.off_price}
                    </span>
                  </span>

                  <div className="flex justify-between items-center bg-red-500 m-l-4 p-lr-8 rounded-full font-semibold text-white">
                    <span className="font-medium text-white fs-8">?% off</span>
                  </div>
                </>
              )} */}
              </span>

              {productDetail?.type != "token-product" && (
                <button
                  onClick={() =>
                    AccessToken ? handleAddtoCart() : navigate("/signin")
                  }
                >
                  <LoadingComponent
                    loadingGroup={"addToCartLoading"}
                    loadingDesign={<ClipLoader color="#fed801" size={16} />}
                  >
                    <ShoppingCartIcon className="flex justify-center items-center w-[25px] h-[25px] text-500 shrink-0" />
                  </LoadingComponent>
                </button>
              )}
            </span>
          </section>

          {/* <section className="m-b-16 m-b-24 mt-1 p-lr-16 container">
          <div className="flex justify-between items-center border-[1px] border-gold-100 bg-gold-50 px-4 py-2 rounded-full font-semibold text-white">
            <span className="flex items-center">
              <span className="font-medium text-gold-900 fs-12 pe-1">
                get
              </span>
              <span className="font-bold text-gold-900 fs-12">50</span>
              <span className="flex gap-[10px] bg-gold-500 m-lr-4 p-all-4 rounded-full">
                <StarIcon id="StarIcon" className="flex justify-center items-center w-[6px] h-[6px] text-white shrink-0" />
              </span>
              <span className="font-medium text-gold-900 fs-12 pe-1">
                for first time ordering
              </span>
            </span>

            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
            </svg>
          </div>
        </section> */}

          <section className="m-b-8 cursor-default p-lr-16 container">
            <div className="flex gap-[2px]">
              <span className="font-medium fs-12">{productDetail?.rating_average}</span>

              {[...Array(5)].map((_, index) => (
                <StarIcon
                  key={index}
                  className={`flex justify-center items-center w-[16px] h-[16px] ${
                    index < Math.floor(productDetail?.rating_average)
                      ? 'text-gold-500'  
                      : index < productDetail?.rating_average
                      ? 'text-gold-500 opacity-50'  
                      : 'text-gray-300' 
                  } shrink-0`}
                />
              ))}

              <button
                onClick={() => setSeeReview(!seeReview)}
                className="m-l-16 text-blue-500 fs-12"
              >
                {productDetail?.reviews?.length > 1
                  ? "See all " + productDetail?.reviews?.length + " reviews"
                  : getLanguageFile.see_review}
              </button>
            </div>

            <div className="border-Light/active my-4 border-t-2"></div>
          </section>

          {/* <section className="m-b-8 p-lr-16 container">
          <div className="m-b-8 font-medium fs-12">
            Color : 2
          </div>

          <div className="flex gap-[8px]">
            <div className="border-[1px] border-Grey/Darker bg-Light/active rounded-full w-[30px] h-[30px]" />
            <div className="border-[1px] bg-red-300 rounded-full w-[30px] h-[30px]" />
            <div className="border-[1px] bg-green-300 rounded-full w-[30px] h-[30px]" />
            <div className="border-[1px] bg-gold-300 rounded-full w-[30px] h-[30px]" />
          </div>

          <div className="border-Light/active my-4 border-t-2"></div>

        </section> */}

          <section className="m-b-8 p-lr-16 container fs-12">
            <div
              dangerouslySetInnerHTML={{ __html: productDetail?.description }}
            />
            {/* <div className="m-b-8 font-medium fs-12">
            {productDetail?.description}
          </div> */}
          </section>

          {/* <section className="m-b-8 p-lr-16 container">
          <div className="m-b-8 font-medium fs-12">
            {getLanguageFile.delivery_fee}
          </div>

          <div className="m-b-8 font-bold fs-12">
            MMK260,000
          </div>

          <div className="m-b-8 fs-12">
            estimated arrival time : 3 to 4 days
          </div>

          <div className="border-Light/active my-4 border-t-2"></div>

        </section> */}

          {seeReview && (
            <section className="m-b-8 p-lr-16 container">
              <button
                onClick={() => setReviewModalToggle(true)}
                className="flex justify-center items-center border-[1px] border-500 bg-white m-tb-16 p-lr-16 p-tb-6 rounded-full font-semibold text-500 fs-11"
              >
                {getLanguageFile.post_review}
              </button>

              <div className="m-b-8 font-medium fs-12">
                {"Reviews (" + productDetail?.reviews?.length + ")"}
              </div>

              {productDetail?.reviews?.map((eachReview, key) => (
                <div
                  key={key}
                  className="bg-foundation-grey-light-hover m-b-8 p-all-8"
                >
                  <div className="flex justify-between items-center">
                    <div className="flex items-center mb-1">
                      <img
                        src={eachReview?.user_image || Avatar}
                        alt="Slide 1"
                        className="flex items-center gap-[4px] rounded-full w-[24px] me-1"
                      />
                      <span className="font-medium fs-12">
                        {eachReview?.user_name}
                      </span>
                    </div>

                    {/* <span className="text-300 fs-12">30 Jan 2024</span> */}
                  </div>

                  <div className="flex gap-[2px] mb-1">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <StarIcon
                        className={`flex w-[16px] h-[16px] justify-center items-center shrink-0 ${
                          star <= eachReview?.rating
                            ? "text-yellow-500"
                            : "text-gray-400"
                        }`}
                      />
                    ))}
                  </div>

                  <p className="text-400 fs-12">{eachReview?.comment}</p>
                </div>
              ))}
            </section>
          )}

          <section className="m-b-8 p-lr-16 container cursor-default">
            <div className="flex justify-between items-center mb-4">
              <p className="font-bold fs-14">{getLanguageFile.quantity}</p>
              <div className="flex justify-between items-center">
                <button
                  onClick={() => setBuyAmount(buyAmount - 1)}
                  className="flex justify-center items-center bg-gray-200 rounded-full w-8 h-8"
                >
                  -
                </button>
                <span className="mx-2 font-bold fs-14">
                  <input
                    ref={inputRef}
                    className="w-[25px] text-center"
                    type="number"
                    min="0"
                    max="1000000"
                    step="1"
                    defaultValue={buyAmount}
                    onBlur={() => {
                      const value = inputRef.current.value;
                      if (!isNaN(value) && /^\d*$/.test(value)) {
                        setBuyAmount(Number(value));
                      }
                    }}
                    onKeyDown={(event) => {
                      if (
                        !/[0-9]/.test(event.key) &&
                        event.key !== "Backspace"
                      ) {
                        event.preventDefault();
                      } else {
                        if (event.key === "Enter") {
                          const value = inputRef.current.value;
                          if (!isNaN(value) && /^\d*$/.test(value)) {
                            setBuyAmount(Number(value));
                          }
                          inputRef.current.blur();
                        }
                      }
                    }}
                    onChange={() => {
                      if (debounceTimeout.current) {
                        clearTimeout(debounceTimeout.current); // Clear previous debounce timer
                      }
                      debounceTimeout.current = setTimeout(() => {
                        const value = inputRef.current.value;
                        if (!isNaN(value) && /^\d*$/.test(value)) {
                          setBuyAmount(Number(value));
                        }
                      }, 1000);
                    }}
                  />
                </span>
                <button
                  onClick={() => setBuyAmount(buyAmount + 1)}
                  className="flex justify-center items-center bg-gray-200 rounded-full w-8 h-8"
                >
                  +
                </button>
              </div>
            </div>
            {productDetail?.actual_price && (
              <div className="flex justify-end items-center me-1">
                {getLanguageFile.total}:{" "}
                {productDetail?.actual_price * buyAmount}
              </div>
            )}
          </section>
        </LoadingComponent>
      </div>
      <div className="bottom-0 bg-white shadow-md-top p-lr-16 w-100 max-w-[393px] sm:max-w-[none]">
        <div
          className={`${
            productDetail?.type != "token-product"
              ? "grid grid-cols-2 gap-2"
              : "grid grid-cols-1 gap-2"
          }`}
        >
          <LoadingComponent
            loadingGroup={"productDetail"}
            loadingDesign={
              <>
                <div className="flex justify-center items-center bg-white m-tb-16 p-lr-32 p-tb-12 rounded-full w-100 font-semibold text-white">
                  Dan
                </div>
                <div className="flex justify-center items-center bg-white m-tb-16 p-lr-32 p-tb-12 rounded-full w-100 font-semibold text-white">
                  Demo
                </div>
              </>
            }
          >
            <button
              onClick={() =>
                AccessToken ? handleBuyNow() : navigate("/signin")
              }
              className="flex justify-center items-center border-[1px] border-gold-500 bg-white m-tb-16 p-lr-32 p-tb-12 rounded-full w-100 font-semibold text-500"
            >
              {getLanguageFile.buy_now}
            </button>

            {productDetail?.type != "token-product" && (
              <button
                onClick={() =>
                  AccessToken ? BuyTicket() : navigate("/signin")
                }
                className="flex justify-center items-center bg-gold-500 m-tb-16 p-lr-32 p-tb-12 rounded-full w-100 font-semibold text-500"
              >
                {getLanguageFile.buy_token}
              </button>
            )}
          </LoadingComponent>
        </div>
      </div>
      <ReviewModal
        productDetail={productDetail}
        visible={reviewModalToggle}
        getLanguageFile={getLanguageFile}
        onClose={() => setReviewModalToggle(false)}
        setAlertmessage={setAlertmessage}
        setServerError={setServerError}
      />
    </>
  );
}
